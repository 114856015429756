class Tool {
  id = "";
  title = "";
  createdBy = "";
  type = "";
  desc = "";
  category = "";
  Icon = "";
  permissions = [];

  fromColor = "";
  toColor = "";

  to = "";
  api = "";

  introMessage = "";

  searchKey = "";

  prompts = [];
  uses = 0;

  apiCreated = false;
  canEdit = false;

  aiParams = {};
  aiPrompts = {};

  output = {};
  historyId = "";
  userId = "";

  customMessages = {};
  enableOneSession = false;
  enableOneIpSession = false;
  useContext = false;
  options = {};
  isLocked = false;

  constructor(
    id,
    title,
    createdBy,
    type,
    desc,
    category,
    Icon,
    permissions,
    fromColor,
    toColor,
    to,
    api,
    introMessage,
    searchKey,
    prompts,
    uses,
    apiCreated,
    canEdit,
    aiParams,
    aiPrompts,
    output,
    historyId,
    userId,
    customMessages,
    enableOneSession,
    enableOneIpSession,
    useContext,
    options,
    isLocked,
  ) {
    this.id = id;
    this.title = title;
    this.createdBy = createdBy;
    this.type = type;
    this.desc = desc;
    this.category = category;
    this.Icon = Icon;
    this.permissions = permissions;
    this.fromColor = fromColor;
    this.toColor = toColor;
    this.to = to;
    this.api = api;
    this.introMessage = introMessage;
    this.searchKey = searchKey;
    this.prompts = prompts;
    this.uses = uses;
    this.apiCreated = apiCreated;
    this.canEdit = canEdit;
    this.aiParams = aiParams;
    this.aiPrompts = aiPrompts;
    this.output = output;
    this.historyId = historyId;
    this.userId = userId;
    this.customMessages = customMessages;
    this.enableOneSession = enableOneSession;
    this.enableOneIpSession = enableOneIpSession;
    this.useContext = useContext;
    this.options = options;
    this.isLocked = isLocked;
  }

  static fromJSON(json) {
    return new Tool(
      json._id,
      json.title,
      json.createdBy?.username,
      json.type,
      json.desc,
      json.category,
      json.Icon,
      json.permissions,
      json.fromColor,
      json.toColor,
      json.to,
      json.api,
      json.introMessage,
      json.searchKey,
      json.prompts,
      json.prommptUses,
      json.apiCreated,
      json.canEdit,
      json.aiParams,
      json.aiPrompts,
      json.output,
      json.historyId,
      json.createdBy?._id,
      json.customMessages,
      json.enableOneSession,
      json.enableOneIpSession,
      json.useContext,
      json.options,
      json.isLocked
    );
  }
}

export default Tool;
