const { v4: uuidv4 } = require("uuid");
const {
  ToolType,
  ToolCategories,
  DefaultModel,
} = require("../../NewTool/helper");

class NewTool {
  id = "";
  toolName = "";
  toolCategory = "";
  toolType = "";
  introMessage = "";
  prompts = [];
  uiStructure = [];
  searchKey = "";
  historyId = "";
  userId = "";
  createdBy = "";
  to = "";
  historyVersion = 0;
  useContext = false;

  constructor(
    id,
    toolName,
    toolCategory,
    toolType,
    introMessage,
    prompts,
    uiStructure,
    searchKey,
    historyId,
    userId,
    createdBy,
    to,
    aiPrompts,
    useContext,
  ) {
    this.id = id;
    this.toolName = toolName;
    this.toolCategory = toolCategory;
    this.toolType = toolType;
    this.introMessage = introMessage;
    this.prompts = prompts;
    this.uiStructure = uiStructure;
    this.searchKey = searchKey;
    this.historyId = historyId;
    this.userId = userId;
    this.createdBy = createdBy;
    this.to = to;
    this.aiPrompts = aiPrompts;
    this.useContext = useContext;
  }

  static fromTool(tool) {
    const prompts = Object.keys(tool.aiPrompts);
    const uiStructure = tool.prompts[0].prompts.map(el => ({
      uiType: el.type,
      uiValue: el.attr || "",
      key: Math.random(),
      uiText: el.value || "",
      title: el.title || "",
      uiOptions: el.options.map(e => e.value).join(","),
      min: el.min,
      max: el.max,
      condition: el.condition || prompts[0],
    }));
    const historyId = tool.historyId || uuidv4();

    return new NewTool(
      tool.id,
      tool.title,
      tool.category,
      tool.type,
      tool.introMessage,
      Object.values(tool.aiPrompts).map(el => ({
        ...el, options: el.options || {
          maxTokens: 1024,
          bestOf: 1,
          topP: 1,
          frequencyPenalty: 1,
          presencePenalty: 0,
          n: 1,
          temperature: 0.2,
        }
      })),
      uiStructure,
      tool.searchKey,
      historyId,
      tool.userId,
      tool.createdBy,
      tool.to,
      tool.aiPrompts,
      tool.useContext,
    );
  }

  static init() {
    const historyId = uuidv4();
    return new NewTool(
      "",
      "",
      ToolCategories[0],
      ToolType.public.key,
      "",
      [
        {
          key: "prompt1",
          model: DefaultModel,
          options: {
            maxTokens: 1024,
            bestOf: 1,
            topP: 1,
            frequencyPenalty: 1,
            presencePenalty: 0,
            n: 1,
            temperature: 0.2,
          },
          variables: [],
        },
      ],
      [],
      "",
      historyId,
      "",
      "",
      "",
      false
    );
  }

  static toAPIJson(tool) {
    return {
      title: tool.toolName,
      type: tool.toolType,
      introMessage: tool.introMessage,
      prompts: tool.prompts,
      uiStructure: tool.uiStructure,
      searchKey: tool.searchKey,
      historyId: tool.historyId,
      userId: tool.userId,
      useContext: tool.useContext
    };
  }
}

export default NewTool;
