import React from "react";
import { Icon } from "../Icons";

function Header({
  title,
  showShareOption,
  shareFc,
  canEdit,
  toEdit,
  toUse,
  getAnalytics,
  toolId,
  category,
  options,
  currentOption,
  children,
  selected,
}) {
  return (
    <header className="tool-titlebar container">
      <h1>{title}</h1>
      {canEdit && (
        <ul className="nav nav-pills nav-fill bg-secondary-subtle">
          <li className="nav-item">
            {/* TODO: this should be a link with a url, not an onclick action */}
            <button
              className={`nav-link ${
                selected === "new" || selected === "edit" ? "active" : ""
              }`}
              onClick={() => {
                if (toEdit) toEdit();
              }}
            >
              <Icon name="pencil-square" />
              &nbsp; Edit
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${selected === "use" ? "active" : ""} ${
                selected === "new" ? "disabled" : ""
              }`}
              onClick={() => {
                if (toUse) toUse();
              }}
              disabled={selected === "new"}
            >
              <Icon name="play-fill" />
              &nbsp; Use
            </button>
          </li>
          <li className="nav-item">
            {/* TODO: this should be a link with a url, not an onclick action */}
            <button
              className={`nav-link ${selected === "new" ? "disabled" : ""}`}
              onClick={() => shareFc()}
              disabled={selected === "new"}
            >
              <Icon name="box-arrow-up" />
              &nbsp; Share
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${selected === "new" ? "disabled" : ""}`}
              onClick={() => getAnalytics()}
              disabled={selected === "new"}
            >
              <Icon name="graph-up" />
              &nbsp; Stats
            </button>
          </li>
        </ul>
      )}

      {/* {options && (
        <>
          {options.map((option, index) => (
            <Option key={index} currentOption={currentOption} {...option} />
          ))}
        </>
      )} */}

      {children}
    </header>
  );
}

// export function Option({ title, Icon, onClick, currentOption, color }) {
//   const active = currentOption === title;
//   return (
//     <button
//       type="button"
//       className={active ? 'active' : ''}
//       onClick={() => onClick(title)}
//     >
//       {title}
//     </button>
//   );
// }

export default Header;
