import React, { Component } from "react";
import { NavLink, Switch, Route, withRouter, Redirect } from "react-router-dom";
import { CheckIcon, UserIcon, LockClosedIcon } from "@heroicons/react/outline";
import { Helmet } from "react-helmet-async";
import { observable, makeObservable } from "mobx";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class Login extends Component {
  @observable email = "";
  @observable password = "";
  @observable fname = "";
  @observable lname = "";
  @observable errorMessage = "";

  constructor() {
    super();
    makeObservable(this);
  }

  onChange = val => {
    this.currentPromptOption = val;
    console.log(this.currentPromptOption);
  };

  onChangeAny = (val, attr) => {
    this[attr] = val;
    this.errorMessage = "";
  };

  onLogin = async e => {
    try {
      e.preventDefault();
      const response = await this.props.store.api
        .post("/auth/signin", {
          email: this.email,
          password: this.password,
        })
        .then(({ data }) => data);

      if (response.profile.status !== "not_confirmed") {
        await this.props.store.loginWithDataTokenAndProfile(response);
        await this.props.store.init();
      } else {
        this.props.history.push(`/change-password/${response.profile._id}`);
        throw new Error("not-confirmed");
      }
    } catch (err) {
      if (err.message !== "not-confirmed") {
        console.log("Err:", err);
        console.log(err?.response?.data?.message);
        if (err?.response?.data?.message) {
          this.errorMessage = err?.response?.data?.message;
        }
      }
    }
  };

  onSignup = async e => {
    try {
      e.preventDefault();
      this.errorMessage = "";
      console.log("signup");
      const response = await this.props.store.api
        .post("/auth/signup", {
          email: this.email,
          password: this.password,
          fname: this.fname,
          lname: this.lname,
          referral: this.props.store.referral,
        })
        .then(({ data }) => data);
      console.log(`onSignup`, response);
      if (response.token && response.profile) {
        this.props.store.loginWithDataTokenAndProfile(response);
      }
    } catch (err) {
      console.log(err);
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
      }
    }
  };

  // Currently Selected Input Option

  render() {
    return (
      <>
        <Helmet>
          <title>Login - Vuo AI</title>
        </Helmet>
        <div className="m-auto">

          <div className="p-3 mb-3">
            <img src="/logo.svg" alt="Vuo AI logo" width="300" />
          </div>

          {/*<NavLink
            to="/login"
            className=""
          >
            Login
          </NavLink>*/}
          {/*<NavLink
            to="/signup"
            className=""
          >
            Signup
          </NavLink>*/}

          <Switch>
            <Route path="/login">
              <Logon
                landingPageUrl={this.props.store.landingPageUrl}
                email={this.email}
                password={this.password}
                signUp={this.signUpWithGoogle}
                onChange={this.onChangeAny}
                onLogin={this.onLogin}
              />
            </Route>
            {/*<Route path="/signup">
              <Signup
                email={this.email}
                password={this.password}
                fname={this.fname}
                lname={this.lname}
                onChange={this.onChangeAny}
                onSignup={this.onSignup}
              />
            </Route>*/}
            <Route>
              <Redirect to="/login" />
            </Route>
          </Switch>

          {this.errorMessage ? (
            <div className="error">
              {this.errorMessage}
            </div>
          ) : null}

        </div>
      </>
    );
  }
}

const Logon = observer(
  ({
    active,
    email,
    password,
    onChange,
    onLogin,
    _landingPageUrl,
    _signUp,
  }) => (
    <>
      <form onSubmit={onLogin} className="card" style={{ maxWidth: '30rem' }}>
        <div className="vstack gap-3 p-3">
          <div className="form-field">
            <label
              htmlFor="email"
              className="form-label"
            >
              Email
            </label>
            <input
              id="email"
              value={email}
              onChange={e => onChange(e.target.value, "email")}
              focus="true"
              type="email"
              autoComplete="email"
              className="form-control"
            />
          </div>
          <div className="form-field">
            <label
              htmlFor="password"
              className="form-label"
            >
              Password
            </label>
            <input
              id="password"
              value={password}
              onChange={e => onChange(e.target.value, "password")}
              type="password"
              autoComplete="current-password"
              className="form-control"
            />
          </div>
          <button
            type="submit"
            className="mt-3 btn btn-primary"
          >
            Log in
          </button>
        </div>
      </form>
    </>
  )
);

const Signup = observer(
  ({ active, email, password, fname, lname, onChange, onSignup }) => (
    <>
      <form onSubmit={onSignup}>
        <div className="vstack gap-3 p-3">
          <div className="form-field">
            <label
              htmlFor="fullname"
              className="form-label"
            >
              Full name
            </label>
            <input
              id="fullname"
              value={fname}
              type="text"
              onChange={e => onChange(e.target.value, "fname")}
              autoComplete="name"
              className="form-control"
            />
          </div>
          <div className="">
            <label
              htmlFor="email"
              className="form-label"
            >
              Email
            </label>
            <input
              id="email"
              value={email}
              onChange={e => onChange(e.target.value, "email")}
              focus="true"
              type="email"
              autoComplete="email"
              className="form-control"
            />
          </div>
          <div className="">
            <label
              htmlFor="password"
              className="form-label"
            >
              Password
            </label>
            <input
              value={password}
              onChange={e => onChange(e.target.value, "password")}
              type="password"
              autoComplete="new-password"
              className="form-control"
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary"
          >
            Sign Up
          </button>
        </div>
      </form>
    </>
  )
);

export default withRouter(Login);
