import React, { Component } from "react";
import { observable, makeObservable } from "mobx";
import { Helmet } from "react-helmet-async";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class Context extends Component {
  @observable value = "";
  @observable showConfirmation = false;

  constructor(props) {
    super(props);

    makeObservable(this);
    this.value = this.props.store.context;
  }

  async componentDidMount() {
    if (!this.value) {
      await this.props.store.getContext();
      this.value = this.props.store.context;
    }
  }

  onChangeContext = value => {
    this.value = value;
    this.showConfirmation = false;
    console.log("Value: ", this.value);
  };

  onSave = async () => {
    await this.props.store.updateContext(this.value);
    this.showConfirmation = true;
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Context - Vuo AI</title>
        </Helmet>
        <main>
          <div className="pb-5" />
          <div className="row">
            <div className="container">
              <div className="form-floating mx-5">
                <textarea
                  placeholder="Context..."
                  id="context-text"
                  defaultValue={this.value}
                  onChange={e => this.onChangeContext(e.target.value)}
                  className="form-control"
                />
                <label htmlFor="context-text">Context</label>
              </div>
            </div>
          </div>

          <div className="pb-3" />

          <div className="row align-items-center">
            <div className="col-4">
              <button
                type="button"
                className="btn btn-primary mx-5"
                onClick={() => this.onSave()}
              >
                Save Context
              </button>
            </div>
            {this.showConfirmation && (
              <div className="col-4">Saved Context!</div>
            )}
          </div>
        </main>
      </>
    );
  }
}

export default Context;
