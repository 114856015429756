import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable, makeObservable, computed } from "mobx";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { ToolModelType, needsMessages } from "../NewTool/helper";
import { Icon, SpinnerLoader } from "../Icons";

@inject("store")
@observer
class TestModal extends Component {
  @observable prompts = [];
  @observable engines = [];
  @observable roles = [];
  @observable variables = [];
  @observable variableInputs = [];
  @observable idxSelection;
  @observable selection;
  @observable outputs = [];
  @observable useContext = false;
  @observable options = {};

  @observable loading = false;
  eventListener;

  constructor(props) {
    super(props);
    makeObservable(this);

    this.prompts = [this.props.initialPrompt || ""];
    this.engines = [
      this.props.initialModel || ToolModelType["gpt-3.5-turbo"].key,
    ];
    this.roles = [this.props.initialRole || ""];
    this.variables = [...this.props.initialVariables];
    this.variableInputs = [{}];
    this.outputs = [[""]];
    this.pickedPolishedPrompt = this.props.pickedPolishedPrompt;
    this.useContext = this.props.useContext;
    this.options = this.props.options;
  }

  componentDidMount() {
    this.eventListener = document.addEventListener("keydown", e => {
      if (e.key === "Escape") {
        this.props.setShowModal(false);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.eventListener);
  }

  @computed get showModal() {
    return this.props.showModal;
  }

  addVariable = () => {
    this.variables = [...this.variables, this.selection];
  };

  removeVariable = word => {
    this.variables = this.variables.filter(val => val !== word);
  };

  changePrompt = (val, selection, idx) => {
    this.prompts[idx] = val;
    if (selection) {
      this.selection =
        selection.start === selection.end
          ? ""
          : val.substr(selection.start, selection.end - selection.start);
      this.idxSelection = idx;
    }
  };

  pickPrompt = idx => {
    this.pickedPolishedPrompt(
      this.prompts[idx],
      this.engines[idx],
      this.variables,
      this.roles[idx]
    );
    this.props.setShowModal(false);
  };

  addPrompt = () => {
    this.prompts = [...this.prompts, this.props.initialPrompt];
    this.engines = [...this.engines, ToolModelType["gpt-3.5-turbo"].key];
    const outputs = [];
    this.variableInputs.forEach(_el => outputs.push(""));
    this.outputs = [...this.outputs, outputs];
  };

  addVariableInput = () => {
    this.variableInputs = [...this.variableInputs, {}];
    this.outputs.forEach(out => {
      out.push("");
    });
  };

  onChangeInput = (el, val, idx) => {
    this.variableInputs[idx][el] = val;
  };

  onChangeToolPromptModel = (val, idx) => {
    this.engines[idx] = val;
    this.roles[idx] = "";
  };

  onChangePromptRole = (val, idx) => {
    this.roles[idx] = val;
  };

  run = () => {
    this.variableInputs.forEach((input, idx) => {
      this.prompts.forEach(async (pr, id) => {
        let output = `${pr}`;
        Object.entries(input).forEach(([key, value]) => {
          while (output.includes(key)) {
            output = output.replace(key, value);
          }
        });

        this.loading = true;
        const response = await this.props.store.api.post(
          "/ai/tools/-/internal-helper",
          {
            prompt: output,
            "--internal-use-engine": this.engines[id],
            "--internal-use-role": this.roles[id],
            "--internal-no-history": true,
            "--internal-use-options": {
              useContext: this.useContext,
              ...this.options,
            },
          }
        );
        this.loading = false;
        this.outputs[id][idx] = response.data.output.trim();
      });
    });
  };

  render() {
    const VariablesInputs =
      this.variableInputs &&
      this.variables &&
      this.variableInputs.map((val, idx) => (
        <div key={`variable-${idx}`} className="card mb-3">
          <div className="card-body">
            {this.variables.map(el => (
              <div key={el} className="mb-3">
                <label htmlFor={el} className="form-label">
                  {el}
                </label>
                <input
                  type="text"
                  id={el}
                  className="form-control"
                  onChange={e => this.onChangeInput(el, e.target.value, idx)}
                />
              </div>
            ))}
          </div>
        </div>
      ));
    return (
      <>
        {this.showModal ? (
          <div
            className="modal modal-xl background-modal"
            style={{ display: "block" }}
            onClick={() => {
              this.props.setShowModal(false);
            }}
            onKeyDown={e => {
              if (e.key === "Escape") {
                this.props.setShowModal(false);
              }
            }}
          >
            <div
              className="modal-dialog"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title">Prompt polish</h3>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => this.props.setShowModal(false)}
                  >
                    {" "}
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-6">
                        <div className="hstack gap-3 mb-3">
                          <h4 className="m-0">Prompts</h4>
                          <button
                            className="btn btn-primary"
                            onClick={() => this.addPrompt()}
                          >
                            <Icon name="plus-lg" />
                            &nbsp; Add prompt
                          </button>
                        </div>
                        {this.prompts.map((prompt, idx) => (
                          <div className="card mb-3" key={`prompt-${idx}`}>
                            <div className="card-body">
                              <div className="mb-3">
                                <label
                                  htmlFor={`select-tool-prompt-${idx}`}
                                  className="form-label"
                                >
                                  Model
                                </label>
                                <select
                                  id={`select-tool-prompt-${idx}`}
                                  className="form-select"
                                  value={this.engines[idx]}
                                  onChange={e =>
                                    this.onChangeToolPromptModel(
                                      e.target.value,
                                      idx
                                    )
                                  }
                                >
                                  {Object.values(ToolModelType).map(
                                    ({ key, text }) => (
                                      <option key={key} value={key}>
                                        {text}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              {needsMessages(this.engines[idx]) && (
                                <div className="mb-3">
                                  <label
                                    htmlFor={`tool-role-${idx}`}
                                    className="form-label"
                                  >
                                    System Role
                                  </label>
                                  <input
                                    type="text"
                                    id={`tool-role-${idx}`}
                                    className="form-control"
                                    onChange={e =>
                                      this.onChangePromptRole(
                                        e.target.value,
                                        idx
                                      )
                                    }
                                    value={this.roles[idx]}
                                  />
                                </div>
                              )}
                              <div className="mb-3">
                                <label
                                  htmlFor={`tool-role-${idx}`}
                                  className="form-label"
                                >
                                  Prompt
                                </label>
                                <HighlightWithinTextarea
                                  value={prompt || ""}
                                  onChange={(val, sel) =>
                                    this.changePrompt(val, sel, idx)
                                  }
                                  highlight={this.variables}
                                />
                              </div>
                              <div className="hstack gap-2 mb-3">
                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    this.addVariable();
                                  }}
                                >
                                  Make variable
                                </button>
                                {this.variables &&
                                  this.variables.map(val => (
                                    <button
                                      key={val}
                                      className="btn btn-sm bg-warning-subtle"
                                      onClick={() => this.removeVariable(val)}
                                    >
                                      {val}
                                      <Icon name="x" />
                                    </button>
                                  ))}
                              </div>

                              {this.variableInputs.map((_out, id) => (
                                <div className="mb-3" key={`message-${id}`}>
                                  <label htmlFor={`message-${id}`}>
                                    {`Output ${id + 1}`}
                                  </label>
                                  <textarea
                                    id={`message-${id}`}
                                    rows="4"
                                    className="form-control"
                                    disabled
                                    value={this.outputs[idx][id]}
                                  />
                                </div>
                              ))}
                              <button
                                className="btn btn-primary"
                                onClick={() => this.pickPrompt(idx)}
                              >
                                Pick this prompt
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-6">
                        <div className="hstack gap-3 mb-3">
                          <h4 className="m-0">Variables</h4>
                          <button
                            className="btn btn-primary"
                            onClick={() => this.addVariableInput()}
                          >
                            <Icon name="plus-lg" />
                            &nbsp; Add variable-set
                          </button>
                        </div>
                        {VariablesInputs}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.run()}
                    disabled={this.loading}
                  >
                    {this.loading && <SpinnerLoader />}
                    {this.loading ? "Running..." : "Run Tests"}
                  </button>
                  <div className="flex-grow-1" />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => this.props.setShowModal(false)}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default TestModal;
