import React, { useEffect } from "react";

const BasicModal = ({
  showModal,
  title,
  action,
  negativeAction,
  setShowModal,
  yesText,
  yesPostive,
  noText,
  hasBackground,
}) => {
  useEffect(() => {
    const eventListener = document.addEventListener("keydown", e => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    });
    return () => {
      document.removeEventListener("keydown", eventListener);
    };
  }, []);
  return (
    <>
      {showModal ? (
        <>
          <div
            onClick={() => {
              if (hasBackground) {
                setShowModal(false);
              }
            }}
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl lg:w-3/5 md:2/3"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">{`${title}`}</h3>
                </div>
                {/* body */}
                {/* footer */}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-gray-100 text-black hover:bg-gray-200 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (negativeAction) negativeAction();
                      else setShowModal(false);
                    }}
                  >
                    {noText || "No"}
                  </button>
                  <button
                    className={`bg-gray-100 ${
                      yesPostive ? "text-green-500" : "text-red-500"
                    } hover:bg-gray-200 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150`}
                    type="button"
                    onClick={async () => {
                      action();
                      setShowModal(false);
                    }}
                  >
                    {yesText || "Yes"}
                  </button>
                </div>
              </div>
              <button
                className="absolute right-2 top-0 p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
          </div>
          <div
            tabIndex="-1"
            className={`fixed inset-0 z-40 ${
              hasBackground ? "background-modal" : ""
            }`}
          />
        </>
      ) : null}
    </>
  );
};

export default BasicModal;
