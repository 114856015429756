import React, { Component, Fragment } from "react";

import Select, { components } from "react-select";

export default class SingleSelect extends Component {
  state = {
    isClearable: false,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  };

  toggleClearable = () =>
    this.setState(state => ({ isClearable: !state.isClearable }));
  toggleDisabled = () =>
    this.setState(state => ({ isDisabled: !state.isDisabled }));
  toggleLoading = () =>
    this.setState(state => ({ isLoading: !state.isLoading }));
  toggleRtl = () => this.setState(state => ({ isRtl: !state.isRtl }));
  toggleSearchable = () =>
    this.setState(state => ({ isSearchable: !state.isSearchable }));
  render() {
    const { isClearable, isSearchable, isDisabled, isLoading, isRtl } =
      this.state;

    return (
      <>
        <Select
          className="form-select"
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          name="select"
          defaultValue={this.props.value}
          isMulti={this.props.isMulti}
          onChange={this.props.onChange}
          options={this.props.options}
        />
      </>
    );
  }
}

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      {props.children}
    </components.SingleValue>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      {props.children}
    </components.Option>
  );
};
