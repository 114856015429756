import React from "react";

function Button({ title, onClick, disabled, children }) {
  return (
    <button
      disabled={disabled}
      className="btn btn-primary"
      onClick={disabled ? null : onClick}
    >
      {title}
      {children}
    </button>
  );
}

export default Button;
