import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { observable, makeObservable } from "mobx";
import { observer, inject } from "mobx-react";
import { Helmet } from "react-helmet-async";

import { checkIfValue, isChatCheck } from "../NewTool/helper";
import Models from "../Models";
import TypistWrapper from "../Components/TypistWrapper";
import config from "../config";
import SmallPopup from "../Components/SmallPopup";
import LockedScreen from "./LockedScreen";
import { Icon } from "../Icons";

const { v4: uuidv4 } = require("uuid");

const { Tool } = Models;

@inject("store")
@observer
class SharedToolChat extends Component {
  @observable tokenId = "";
  @observable tool = {};
  @observable.deep prompts = [];

  @observable history = {};
  @observable loading = false;

  editRef = createRef();
  endRef;

  @observable input = "";
  @observable messages = [];
  @observable dissapearingMessages = [];
  @observable prevMessages = [];

  @observable isWarningOpen = false;

  @observable lastMessage;
  @observable errorMessage;
  @observable showRetry;

  @observable lastPostObj;
  @observable editingMessage = -1;
  editedMessage = "";

  shouldLock = false;
  @observable isLocked = false;
  @observable shouldShowLockScreen = false;

  @observable isDarkMode = false;
  @observable shouldHaveDissapearingMessages = false;
  @observable introMessage = "";

  toolSession = "";

  ws = createRef();

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount(_prevProps, _prevState, _snapshot) {
    const pathName = this.props.location.pathname;
    this.tokenId = pathName.substring(pathName.lastIndexOf("/") + 1);

    const tool = await this.props.store.api
      .get(`/public-tools/get-tool/${this.tokenId}`)
      .then(({ data }) => data);
    this.tool = Tool.fromJSON(tool);
    this.prompts = [...this.tool.prompts];
    this.scrollToBottom();

    if (this.tool.isLocked && this.tool.enableOneIpSession) {
      this.isLocked = true;
      this.shouldShowLockScreen = this.tool.customMessages?.lockedMessage;
      if (!this.shouldShowLockScreen)
        alert(
          tool.customMessages?.lockedMessage || "Chat session already used"
        );
    } else if (this.tool.enableOneSession) {
      if (this.props.store.isLocked(this.tool.id)) {
        this.isLocked = true;
        this.shouldShowLockScreen = this.tool.customMessages?.lockedMessage;
        if (!this.shouldShowLockScreen)
          alert(
            tool.customMessages?.lockedMessage || "Chat session already used"
          );
      } else {
        this.shouldLock = true;
      }
    }

    this.isDarkMode = this.tool.options?.darkMode;
    this.shouldHaveDissapearingMessages =
      this.tool.options?.dissapearingMessages;
    this.introMessage = this.tool.introMessage;
    this.toolSession = uuidv4();

    this.scrollToBottomFnc();
  }

  componentWillUnmount() {
    if (this.ws.current) this.ws.current.close();
  }

  handleKeyPress = async event => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
    if (this.loading || this.input.trim().length === 0 || this.isLocked) return;
    if (event.key === "Enter" && !event.shiftKey) {
      await this.handleSubmit();
    }
  };

  handleMessageEditPress = async event => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }
    if (event.key === "Enter" && !event.shiftKey) {
      this.prevMessages = this.messages;
      this.messages = this.messages.slice(0, this.editingMessage);
      const key = Object.keys(this.tool.aiPrompts)[0];
      this.history[key] = this.history[key].slice(0, this.editingMessage);
      await this.onGenerateClick(key, this.editedMessage);

      this.editingMessage = -1;
      this.editedMessage = "";
      this.isWarningOpen = true;
    }
  };

  handleSubmit = async () => {
    this.editRef.current.innerHTML = "";
    const inputToSend = this.input;
    this.input = "";
    await this.onGenerateClick(
      Object.keys(this.tool.aiPrompts)[0],
      inputToSend
    );
  };

  scrollToBottom = () => {
    if (!this.endRef) return;
    this.endRef.scrollIntoView({ behavior: "smooth" });
  };

  removeDissapearingMessages = elements => {
    this.dissapearingMessages.forEach(el => {
      if (elements.includes(el.key)) {
        el.dontShow = true;
      }
    });
  };

  onGenerateClick = async (key, input) => {
    try {
      this.loading = true;
      const postObj = {};

      if (this.editingMessage > -1) {
        this.editingMessage = -1;
        this.editedMessage = "";
      }

      if (!this.history[key]) {
        this.history[key] = [];
      }
      this.prompts[0].prompts
        .filter(el => checkIfValue(el.type))
        .forEach(prompt => {
          const attr = prompt.attr.replace(` [${key}]`, "");
          const value = input;

          if (
            isChatCheck(
              this.tool.aiPrompts[
                Object.keys(this.tool.aiPrompts)[this.currentPrompt || 0]
              ].model
            )
          ) {
            if (this.history[key].length > 0) {
              postObj["--internal-history"] = [...this.history[key]];
            }

            this.history[key].push({ role: "user", content: value });
            this.messages.push({
              role: "user",
              content: value,
              key: Math.random(),
            });
          }
          postObj[attr] = value;
        });

      this.scrollToBottomFnc();

      postObj.currentPrompt = key;
      if (this.prompts[0].n) {
        postObj.n = this.prompts[this.currentPrompt].n;
      }

      postObj.tool = this.tool.id;
      postObj.visitorId = this.props.store.getVisitorId();
      postObj.toolSession = this.toolSession;
      postObj.shouldLock = this.shouldLock;

      this.lastMessage = "";
      this.errorMessage = "";

      const sessionId = uuidv4();

      this.ws.current = new WebSocket(
        `${config.ws}/shared-tool/${sessionId}`,
        this.tokenId
      );

      this.lastPostObj = postObj;

      this.ws.current.onopen = () => {
        this.ws.current.send(
          JSON.stringify({
            session: sessionId,
            data: postObj,
          })
        );
      };
      this.ws.current.onmessage = ev => {
        const data = JSON.parse(ev.data);
        if (data.type === "done") {
          this.ws.current.close();
          return;
        }
        if (data.type === "error") {
          this.errorMessage = data.message;
          this.showRetry = data.showRetry;
          this.ws.current.close();
          return;
        }
        this.lastMessage += data.message ? data.message : "";
        this.scrollToBottom();
      };
      this.ws.current.onclose = () => {
        if (this.lastMessage) {
          this.history[key].push({
            role: "assistant",
            content: this.lastMessage,
          });
          this.messages.push({
            role: "assistant",
            content: this.lastMessage,
            key: Math.random(),
          });

          if (this.shouldHaveDissapearingMessages) {
            if (this.introMessage) {
              const randomKey = Math.random();
              this.dissapearingMessages.push({
                role: "assistent",
                content: this.introMessage,
                key: randomKey,
              });
              setTimeout(() => {
                this.removeDissapearingMessages([randomKey]);
              }, 9500);
              this.introMessage = "";
            }

            const dissapear = this.messages.slice(0, -2);
            this.dissapearingMessages.push(...dissapear);
            setTimeout(() => {
              this.removeDissapearingMessages(dissapear.map(el => el.key));
            }, 9500);
            this.messages = this.messages.slice(-2);
          }
        }
        this.loading = false;
        this.scrollToBottomFnc();
        if (this.shouldLock) {
          this.shouldLock = false;
          this.props.store.setLockedTools(this.tool.id);
        }
      };
      this.ws.current.onerror = error => {
        console.log("Error", error);
        this.errorMessage = !error.target.protocol
          ? this.tool.customMessages?.failMessage ||
            `The user may not be authenticated or not have enough credits.`
          : this.tool.customMessages?.privateRelayMessage ||
            "Message processing failed. Please try again.";
        this.showRetry = !!error.target.protocol;
      };
    } catch (error) {
      console.log("WS Error: ", error);
    }

    this.scrollToBottomFnc();
    return true;
  };

  onRetryClick = async key => {
    try {
      this.errorMessage = "";
      this.lastMessage = "";
      this.loading = true;

      if (this.editingMessage > -1) {
        this.editingMessage = -1;
        this.editedMessage = "";
      }

      const sessionId = uuidv4();
      this.ws.current = new WebSocket(
        `${config.ws}/shared-tool/${sessionId}`,
        this.tokenId
      );

      this.ws.current.onopen = () => {
        this.ws.current.send(
          JSON.stringify({
            session: sessionId,
            data: this.lastPostObj,
          })
        );
      };
      this.ws.current.onmessage = ev => {
        const data = JSON.parse(ev.data);
        if (data.type === "done") {
          this.ws.current.close();
          return;
        }
        if (data.type === "error") {
          this.errorMessage = data.message;
          this.showRetry = data.showRetry;
          this.ws.current.close();
          return;
        }
        this.lastMessage += data.message ? data.message : "";
        this.scrollToBottom();
      };

      this.ws.current.onclose = () => {
        if (this.lastMessage) {
          this.history[key].push({
            role: "assistant",
            content: this.lastMessage,
          });
          this.messages.push({
            role: "assistant",
            content: this.lastMessage,
            key: Math.random(),
          });

          if (this.shouldHaveDissapearingMessages) {
            if (this.introMessage) {
              const randomKey = Math.random();
              this.dissapearingMessages.push({
                role: "assistent",
                content: this.introMessage,
                key: randomKey,
              });
              setTimeout(() => {
                this.removeDissapearingMessages([randomKey]);
              }, 9500);
              this.introMessage = "";
            }

            const dissapear = this.messages.slice(0, -2);
            this.dissapearingMessages.push(...dissapear);
            setTimeout(() => {
              this.removeDissapearingMessages(dissapear.map(el => el.key));
            }, 9500);
            this.messages = this.messages.slice(-2);
          }
        }
        this.loading = false;
        this.scrollToBottomFnc();
      };

      this.ws.current.onerror = error => {
        console.log("Error", error);
        this.errorMessage = !error.target.protocol
          ? this.tool.customMessages?.failMessage ||
            `The user may not be authenticated or not have enough credits.`
          : this.tool.customMessages?.privateRelayMessage ||
            "Message processing failed. Please try again.";
        this.showRetry = !!error.target.protocol;
      };
    } catch (error) {
      console.log(error);
    }

    this.scrollToBottomFnc();
    return true;
  };

  shouldHaveMargin = text => !/^([0-9].)/.test(text);
  scrollToBottomFnc = () => {
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  };

  render() {
    if (!this.prompts.length) return null;
    if (this.shouldShowLockScreen)
      return (
        <>
          <Helmet>
            <title>{`${this.tool.title} (${this.tokenId}) - Chat [Locked] - Vuo AI`}</title>
          </Helmet>
          <LockedScreen
            darkMode={this.isDarkMode}
            title={this.tool.title}
            message={this.tool.customMessages?.lockedMessage}
            url={this.tool.options?.url}
            urlText={this.tool.options?.urlText}
          />
        </>
      );
    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} (${this.tokenId}) - Chat - Vuo AI`}</title>
        </Helmet>
        {!this.tool.options?.disableHeader && (
          <header className="tool-titlebar container">
            <h1>{this.tool.title}</h1>
          </header>
        )}
        <main className={`tool-chat ${this.isDarkMode ? "text-bg-dark" : ""} `}>
          <div
            className={`tool-chat-messages ${
              this.shouldHaveDissapearingMessages ? "chat-scroll" : ""
            }`}
          >
            {this.introMessage && (
              <div className="tool-chat-message-assistant container">
                <div className="tool-chat-messages-parent">
                  <Icon name="chat-right-quote-fill" className="message" />
                </div>
                <TypistWrapper
                  condition
                  onLineCallback={() => this.scrollToBottom()}
                  content={this.tool.introMessage
                    .split("\n")
                    .map((text, id, ar) => (
                      <p key={id}>{text}</p>
                    ))}
                />
              </div>
            )}
            {this.dissapearingMessages.map((el, idx) => {
              if (!el.dontShow)
                return (
                  <div
                    key={idx}
                    className={`container tool-chat-message-${
                      el.role === "user" ? "user" : "assistant"
                    } dissapearing`}
                  >
                    <div className="tool-chat-messages-parent">
                      {el.role === "user" ? (
                        <Icon name="person-fill" className="message" />
                      ) : (
                        <Icon
                          name="chat-right-quote-fill"
                          className="message"
                        />
                      )}
                    </div>
                    {this.editingMessage !== idx ? (
                      <TypistWrapper
                        condition={false}
                        onLineCallback={() => this.scrollToBottom()}
                        content={el.content.split("\n").map((text, id, ar) => (
                          <p key={id}>{text}</p>
                        ))}
                      />
                    ) : (
                      <div
                        contentEditable
                        id="edit-message-box"
                        onInput={e => {
                          this.editedMessage = e.currentTarget.innerText;
                        }}
                        suppressContentEditableWarning
                        onKeyDown={this.handleMessageEditPress}
                      >
                        {this.editedMessage.split("\n").map((text, id, ar) => (
                          <p key={id}>{text}</p>
                        ))}
                      </div>
                    )}
                  </div>
                );
              return null;
            })}
            {this.messages.map((el, idx) => (
              <div
                key={idx}
                className={`container tool-chat-message-${
                  el.role === "user" ? "user" : "assistant"
                }`}
              >
                <div className="tool-chat-messages-parent">
                  {el.role === "user" ? (
                    <Icon name="person-fill" className="message" />
                  ) : (
                    <Icon name="chat-right-quote-fill" className="message" />
                  )}
                </div>
                {this.editingMessage !== idx ? (
                  <TypistWrapper
                    condition={false}
                    onLineCallback={() => this.scrollToBottom()}
                    content={el.content.split("\n").map((text, id, ar) => (
                      <p key={id}>{text}</p>
                    ))}
                  />
                ) : (
                  <div
                    contentEditable
                    id="edit-message-box"
                    onInput={e => {
                      this.editedMessage = e.currentTarget.innerText;
                    }}
                    suppressContentEditableWarning
                    onKeyDown={this.handleMessageEditPress}
                  >
                    {this.editedMessage.split("\n").map((text, id, ar) => (
                      <p key={id}>{text}</p>
                    ))}
                  </div>
                )}
                {!this.loading &&
                  this.editingMessage !== idx &&
                  !this.tool.options?.disableEditMessages &&
                  el.role === "user" && (
                    <button
                      className="btn btn-outline-dark"
                      onClick={() => {
                        if (this.loading) return;
                        this.editingMessage = idx;
                        this.editedMessage = el.content;
                      }}
                    >
                      <Icon name="pencil-fill" />
                    </button>
                  )}
                {this.editingMessage === idx && (
                  <div className="hstack gap-3">
                    <button
                      className="btn btn-primary"
                      onClick={async () => {
                        this.prevMessages = this.messages;
                        this.messages = this.messages.slice(0, idx);
                        const key = Object.keys(this.tool.aiPrompts)[0];
                        this.history[key] = this.history[key].slice(0, idx);
                        await this.onGenerateClick(key, this.editedMessage);

                        this.editingMessage = -1;
                        this.editedMessage = "";
                        this.isWarningOpen = true;
                      }}
                    >
                      Send
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.editingMessage = -1;
                        this.editedMessage = "";
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            ))}
            {this.loading && this.lastMessage === "" && (
              <div className="tool-chat-message-assistant container">
                <div className="tool-chat-messages-parent">
                  <Icon name="chat-right-quote-fill" className="message" />
                </div>
                <div className="dot-flashing ml-4" />
              </div>
            )}
            {this.loading && this.lastMessage && this.lastMessage.length && (
              <div className="tool-chat-message-assistant container">
                <div className="tool-chat-messages-parent">
                  <Icon name="chat-right-quote-fill" className="message" />
                </div>
                <TypistWrapper
                  condition={false}
                  onLineCallback={() => this.scrollToBottom()}
                  content={this.lastMessage.split("\n").map((text, id, ar) => (
                    <p key={id}>{text}</p>
                  ))}
                />
              </div>
            )}
            {this.errorMessage && this.errorMessage.length && (
              <div className="tool-chat-message-assistant container error">
                <div className="tool-chat-messages-parent">
                  <Icon name="chat-right-quote-fill" className="message" />
                </div>
                <TypistWrapper
                  condition={false}
                  onLineCallback={() => this.scrollToBottom()}
                  content={this.errorMessage.split("\n").map((text, id, ar) => (
                    <p key={id}>{text}</p>
                  ))}
                />
                {this.showRetry && (
                  <button
                    onClick={() =>
                      this.onRetryClick(Object.keys(this.tool.aiPrompts)[0])
                    }
                  >
                    Try again
                  </button>
                )}
              </div>
            )}
            <div
              ref={el => {
                this.endRef = el;
              }}
            />
          </div>
          <form
            className="tool-chat-message-new container"
            onSubmit={async e => {
              e.preventDefault();
              await this.handleSubmit();
            }}
          >
            <div
              contentEditable={!this.isLocked}
              id="message-box"
              className="form-control tool-chat-input"
              ref={this.editRef}
              onInput={e => (this.input = e.currentTarget.innerText)}
              onKeyDown={this.handleKeyPress}
            />
            <button
              className="btn btn-light text-nowrap"
              type="submit"
              disabled={
                this.loading || this.input.trim().length === 0 || this.isLocked
              }
            >
              <Icon name="send-fill" />
              &nbsp; Send
            </button>
          </form>
        </main>

        {this.isWarningOpen ? (
          <Modal
            showModal={this.isWarningOpen}
            setShowModal={() => {
              this.isWarningOpen = false;
              this.editingMessage = -1;
              this.editedMessage = "";
            }}
            action={() => {
              this.messages = this.prevMessages;
              if (this.loading) {
                this.ws.current.close();
                this.lastMessage = "";
                this.loading = false;
              }
            }}
          />
        ) : null}
      </>
    );
  }
}

const Modal = observer(({ showModal, setShowModal, action }) => (
  <SmallPopup
    title="Conversation cleared"
    showModal={showModal}
    setShowModal={setShowModal}
    action={action}
    yesText="Undo"
    noText="Ok"
  />
));

export default withRouter(SharedToolChat);
