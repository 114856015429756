import React, { Component } from "react";
import { PencilIcon, ClockIcon, DuplicateIcon } from "@heroicons/react/outline";
import { UserIcon } from "@heroicons/react/solid";

import Countdown from "react-countdown";
import { observable, makeObservable, computed, toJS } from "mobx";
import { observer, inject } from "mobx-react";
import Body from "../Components/Body";
import Output from "../Components/Output";

import EntryPrompt from "../Components/EntryPrompt";
import EntryInput from "../Components/EntryInput";

import Models from "../Models";
import {
  checkIfConditionalOutput,
  checkIfHeading,
  checkIfNumberValue,
  checkIfOutput,
  checkIfOutputAsValue,
  checkIfOutputNumberValue,
  checkIfOutputs,
  checkIfOutputSelect,
  checkIfOutputSubmit,
  checkIfOutputValue,
  checkIfSelect,
  checkIfSubmit,
  checkIfValue,
  UITypes,
} from "../NewTool/helper";

const { PreviewTool, NewTool } = Models;

@inject("store")
@observer
class ToolPreview extends Component {
  @observable parentTool = this.props.tool;
  @observable currentValues = [];

  @observable error = "";
  @observable output = {};
  @observable loading = false;

  @observable date = Date.now() + 1000;
  countdown = [];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uiChanges !== this.props.uiChanges)
      this.parentTool = this.props.tool;
  }

  @computed get tool() {
    return observer(
      PreviewTool.fromTool(this.parentTool, toJS(this.currentValues))
    );
  }

  @computed get isGenerateButtonDisabled() {
    if (this.loading) {
      return true;
    }
    return false;
  }

  checkMinimumPrompts = () => {
    let shouldReturn = false;

    this.tool.uiElements.forEach(prompt => {
      if (prompt.min) {
        if (prompt.value.length < prompt.min) {
          shouldReturn = true;
          prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
        }
      }
    });

    return shouldReturn;
  };

  contentFilterFlagged = async response => {
    this.error = response.message;

    this.date = Date.now() + 5000;
    this.countdown.forEach(countdown => {
      if (countdown) {
        countdown.stop();
        countdown.start();
      }
    });
    this.loading = false;
  };

  checkOutput = output => {
    if (output) {
      output = output.replace(/^\s+|\s+$/g, "");
    }
    return output;
  };

  onGenerateClick = async (key, type) => {
    try {
      this.error = "";
      this.loading = true;

      const checkMinimumPrompts = this.checkMinimumPrompts();
      if (checkMinimumPrompts) {
        this.loading = false;
        return false;
      }

      const aiPrompt = this.tool.aiPrompts.find(el => el.key === key);
      const prompts = this.tool.aiPrompts.map(el => el.key);
      let usedPrompt = `${aiPrompt.prompt}`;

      if (checkIfSubmit(type)) {
        this.tool.uiElements
          .filter(
            el =>
              checkIfValue(el.type) ||
              checkIfSelect(el.type) ||
              checkIfNumberValue(el.type)
          )
          .forEach(prompt => {
            if (prompt.attr) {
              const attr = prompt.attr.replace(` [${key}]`, "");
              const value = checkIfSelect(prompt.type)
                ? Array.isArray(prompt.value)
                  ? prompt.value.map(el => el.value).join(", ")
                  : prompt.value.value
                : prompt.value;
              while (usedPrompt.includes(attr)) {
                usedPrompt = usedPrompt.replace(attr, value);
              }
            }
          });
      } else {
        this.tool.uiElements
          .filter(el => el.attr.includes(`[${key}]`))
          .filter(
            el =>
              checkIfOutputValue(el.type) ||
              checkIfOutputSelect(el.type) ||
              checkIfOutputNumberValue(el.type)
          )
          .forEach(prompt => {
            if (prompt.attr) {
              const attr = prompt.attr.replace(` [${key}]`, "");
              const value = checkIfOutputSelect(prompt.type)
                ? Array.isArray(prompt.value)
                  ? prompt.value.map(el => el.value).join(", ")
                  : prompt.value.value
                : prompt.value;
              while (usedPrompt.includes(attr)) {
                usedPrompt = usedPrompt.replace(attr, value);
              }
            }
          });
        const outputVariable = this.tool.uiElements.find(
          el => checkIfOutputAsValue(el.type) && el.attr.includes(`[${key}]`)
        );
        if (outputVariable) {
          if (outputVariable.attr) {
            const attr = outputVariable.attr.replace(` [${key}]`, "");
            while (usedPrompt.includes(attr)) {
              usedPrompt = usedPrompt.replace(
                attr,
                this.output[outputVariable.condition || prompts[0]]
              );
            }
          }
        }

        const hasOutput = this.tool.uiElements.find(
          el => checkIfConditionalOutput(el.type) && el.value === key
        );
        if (!hasOutput) [key] = prompts;
      }

      const response = await this.props.store.api.post(
        "/ai/tools/-/internal-helper",
        {
          prompt: usedPrompt,
          "--internal-use-engine": aiPrompt.model,
          "--internal-use-role": aiPrompt.role || "",
          "--internal-tool-title": this.tool.prompt.title,
          "--internal-history-id": this.tool.historyId,
          "--internal-tool-state": NewTool.toAPIJson(this.parentTool),
          "--internal-use-options": {
            useContext: this.tool.useContext,
            ...aiPrompt.options,
          },
        }
      );

      if (this.props.updateHistory) {
        this.props.updateHistory();
      }

      if (!response.data.success) {
        this.contentFilterFlagged(response.data);
        return false;
      }

      if (response.data.output) {
        this.output[key] = this.checkOutput(response.data.output);
      }

      this.date = Date.now() + 10000;
      this.countdown.forEach(countdown => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.countdown.forEach(countdown => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      this.loading = false;
    }
    return null;
  };

  getIcon = () => UserIcon;

  updateUIPromptValue = (attr, value) => {
    const neededValue = this.tool.uiElements.find(el => el.attr === attr);
    const currentValue = this.currentValues.find(el => el.attr === attr);

    if (currentValue) {
      currentValue.value = value;
    } else {
      this.currentValues = [
        ...this.currentValues,
        {
          attr,
          value,
        },
      ];
    }

    neededValue.value = value;
  };

  render() {
    if (!this.tool) return null;
    const currentValue = 0;
    const hasOutput =
      this.tool.uiElements.length &&
      this.tool.uiElements.find(el => checkIfOutput(el.type));
    const submit =
      this.tool.uiElements.length &&
      this.tool.uiElements.find(el => checkIfSubmit(el.type));

    const prompts = this.tool.aiPrompts.map(el => el.key);

    const improveSubmits =
      (this.tool.uiElements.length &&
        this.tool.uiElements.reduce((res, val) => {
          if (checkIfOutputSubmit(val.type)) {
            res.push(val);
          }
          return res;
        }, [])) ||
      [];

    const showOutputPart = key =>
      this.output[key] &&
      this.tool.uiElements.filter(
        el =>
          checkIfOutputs(el.type) &&
          !checkIfOutputSubmit(el.type) &&
          (el.condition || prompts[0]) === key
      ).length > 0;

    const conditionalOutput = (key, condition) => {
      const res = this.tool.uiElements.find(
        el =>
          checkIfConditionalOutput(el.type) &&
          el.value === key &&
          el.condition === condition
      );
      return res;
    };

    return (
      <div>
        <EntryPrompt prompt={this.tool.prompt} currentPrompt={0} index={0}>
          {this.tool.uiElements
            .filter(
              el =>
                checkIfHeading(el.type) ||
                checkIfValue(el.type) ||
                checkIfNumberValue(el.type) ||
                checkIfSelect(el.type)
            )
            .map((promptInput, index) => (
              <EntryInput
                prompt={promptInput}
                key={index}
                language=""
                index={index}
                updateValue={this.updateUIPromptValue}
              />
            ))}

          {submit ? (
            <Countdown
              ref={countdown => (this.countdown[0] = countdown)}
              date={this.date}
              renderer={props => (
                <button
                  className="btn btn-primary"
                  disabled={props.total || this.isGenerateButtonDisabled}
                  onClick={() =>
                    this.onGenerateClick(submit.value, UITypes.SubmitBtn.key)
                  }
                >
                  {props.total
                    ? `Timeout ${props.total / 1000} secs`
                    : submit.title || "Send"}
                </button>
              )}
            />
          ) : null}

          {this.error && <div className="text-danger">{this.error}</div>}
        </EntryPrompt>

        {hasOutput ? (
          <Output
            title={this.tool.output.title}
            desc={this.tool.output.desc}
            Icon={this.getIcon()}
            loading={this.loading}
            output={this.output[prompts[0]]}
            outputs={[]}
            code=""
            language=""
          />
        ) : null}

        {improveSubmits
          ?.filter(el => showOutputPart(el.condition))
          .map(key => (
            <div key={Math.random()}>
              <EntryPrompt
                prompt={this.tool.prompt}
                currentPrompt={0}
                index={0}
                key={Math.random()}
              >
                {this.tool.uiElements
                  .filter(
                    el =>
                      checkIfOutputs(el.type) &&
                      (el.condition || prompts[0]) === key.condition &&
                      el.attr.includes(`[${key.value}]`) &&
                      !checkIfOutputAsValue(el.type) &&
                      !checkIfOutputSubmit(el.type)
                  )
                  .map((promptInput, index) => (
                    <EntryInput
                      prompt={promptInput}
                      key={index}
                      language=""
                      index={index}
                      updateValue={this.updateUIPromptValue}
                    />
                  ))}
                {key ? (
                  <Countdown
                    ref={countdown => (this.countdown[1] = countdown)}
                    date={this.date}
                    renderer={props => (
                      <button
                        className="btn btn-primary"
                        disabled={props.total || this.isGenerateButtonDisabled}
                        onClick={() =>
                          this.onGenerateClick(
                            key.value,
                            UITypes.OutputSubmitBtn.key
                          )
                        }
                      >
                        {props.total
                          ? `Timeout ${props.total / 1000} secs`
                          : key.title || "Send"}
                      </button>
                    )}
                  />
                ) : null}

                {this.error && <div className="text-danger">{this.error}</div>}
              </EntryPrompt>
              {conditionalOutput(key.value, key.condition) ? (
                <Output
                  title={this.tool.output.title}
                  desc={this.tool.output.desc}
                  Icon={this.getIcon()}
                  loading={this.loading}
                  output={this.output[key.value]}
                  outputs={[]}
                  code=""
                  language=""
                />
              ) : null}
            </div>
          ))}
      </div>
    );
  }
}

export default ToolPreview;
