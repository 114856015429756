import Typist from "react-typist";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import Loader from "./Loader";

export const Output = inject("store")(
  observer(
    ({
      store,
      title,
      desc,
      Icon,
      output,
      code,
      language,
      outputs,
      loading,
      children,
    }) => {
      return (
        <div className="card shadow">
          <h3 className="card-header">{title} output</h3>
          <div className="card-body">
            {loading && (
              <>
                <Loader
                  active={loading}
                  style={{ width: "10rem", height: "10rem" }}
                />
              </>
            )}
            <div className="mb-3">
              {code ? null : output ? (
                <div>
                  {output ? (
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {output}
                    </ReactMarkdown>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="mb-3">
              {outputs && outputs.length ? <Outputs outputs={outputs} /> : null}

              {code && code.length ? (
                <CodeEditor padding={30} language={language} value={code} />
              ) : null}
            </div>
            <div className="mb-3">
              <QuickTools outputs={outputs} output={output} code={code} />
            </div>
          </div>
        </div>
      );
    }
  )
);

export const QuickTools = inject("store")(
  observer(({ store, output, outputs, code }) => {
    return (
      <>
        {output || code || (outputs && outputs.length) ? (
          <div className="hstack gap-3 mb-3">
            <button
              className="btn btn-mini btn-primary"
              onClick={() => store.copyToClipboard(output || code || outputs)}
            >
              Copy to clipboard
            </button>
            <button
              className="btn btn-mini btn-primary"
              onClick={() => store.reportToFeedback(output || code || outputs)}
            >
              Report issue
            </button>
          </div>
        ) : null}
      </>
    );
  })
);

export const Tooltip = styled.div`
  display: none;
  white-space: nowrap;
`;

export const Shortcut = styled.div`
  &:hover ${Tooltip} {
    display: flex;
  }
`;
function Outputs({ outputs }) {
  return (
    <div className="">
      <Typist
        stdTypingDelay={0}
        avgTypingDelay={7}
        className="divide-y"
        cursor={{
          show: false,
          blink: false,
          element: "|",
          hideWhenDone: true,
          hideWhenDoneDelay: 250,
        }}
      >
        {outputs.map((output, index) => (
          <div key={index}>{output}</div>
        ))}
      </Typist>
    </div>
  );
}

export default Output;
