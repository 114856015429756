export const UITypes = {
  ValueTextInput: {
    key: "text",
    text: "Value Text Input",
  },
  ValueNumberInput: {
    key: "number",
    text: "Value Number Input",
  },
  ValueTextArea: {
    key: "textarea",
    text: "Value Text Area",
  },
  ValueCode: {
    key: "code",
    text: "Value Code",
  },
  ValueSingleSelect: {
    key: "single-select",
    text: "Value Single Select",
  },
  ValueMultipleSelect: {
    key: "multiple-select",
    text: "Value Multiple Select",
  },
  OutputValue: {
    key: "output-value",
    text: "Output Value",
  },
  OutputTextInput: {
    key: "output-text",
    text: "Output Value Text Input",
  },
  OutputNumberInput: {
    key: "output-number",
    text: "Output Number Input",
  },
  OutputTextArea: {
    key: "output-textarea",
    text: "Output Value Text Area",
  },
  OutputCode: {
    key: "output-code",
    text: "Output Value Code",
  },
  OutputSingleSelect: {
    key: "output-single-select",
    text: "Output Single Select",
  },
  OutputMultipleSelect: {
    key: "output-multiple-select",
    text: "Output Multiple Select",
  },
  H1: {
    key: "h1",
    text: "Headings 1",
  },
  H2: {
    key: "h2",
    text: "Headings 2",
  },
  H3: {
    key: "h3",
    text: "Headings 3",
  },
  P: {
    key: "p",
    text: "Paragraph"
  },
  Output: {
    key: "output",
    text: "Output",
  },
  ConditionalOutput: {
    key: "conditional-output",
    text: "Conditional Output",
  },
  SubmitBtn: {
    key: "submit",
    text: "Submit Button",
  },
  OutputSubmitBtn: {
    key: "output-submit",
    text: "Output Submit Button",
  },
};

export const checkIfValue = type =>
  type === UITypes.ValueTextInput.key ||
  type === UITypes.ValueTextArea.key ||
  type === UITypes.ValueCode.key;
export const checkIfOutputValue = type =>
  type === UITypes.OutputTextInput.key ||
  type === UITypes.OutputTextArea.key ||
  type === UITypes.OutputCode.key;
export const checkIfNumberValue = type => type === UITypes.ValueNumberInput.key;
export const checkIfOutputNumberValue = type =>
  type === UITypes.OutputNumberInput.key;
export const checkIfOutputAsValue = type => type === UITypes.OutputValue.key;
export const checkIfHeading = type =>
  type === UITypes.H1.key || type === UITypes.H2.key || type === UITypes.H3.key || type === UITypes.P.key;
export const checkIfOutput = type => type === UITypes.Output.key;
export const checkIfSubmit = type => type === UITypes.SubmitBtn.key;
export const checkIfOutputSubmit = type => type === UITypes.OutputSubmitBtn.key;
export const checkIfSelect = type =>
  type === UITypes.ValueSingleSelect.key ||
  type === UITypes.ValueMultipleSelect.key;
export const checkIfOutputSelect = type =>
  type === UITypes.OutputSingleSelect.key ||
  type === UITypes.OutputMultipleSelect.key;
export const checkIfConditionalOutput = type =>
  type === UITypes.ConditionalOutput.key;
export const checkIfOutputs = type =>
  type === UITypes.OutputValue.key ||
  type === UITypes.OutputCode.key ||
  type === UITypes.OutputTextInput.key ||
  type === UITypes.OutputTextArea.key ||
  type === UITypes.OutputNumberInput ||
  type === UITypes.OutputSingleSelect.key ||
  type === UITypes.OutputMultipleSelect.key ||
  type === UITypes.OutputSubmitBtn.key ||
  type === UITypes.ConditionalOutput.key;

export const ToolModelType = {
  "gpt-3.5-turbo": {
    key: "gpt-3.5-turbo",
    text: "GPT 3.5",
  },
  "gpt-3.5-turbo-chat": {
    key: "gpt-3.5-turbo-chat",
    text: "GPT 3.5 Chat",
  },
  "gpt-3.5-turbo-16k": {
    key: "gpt-3.5-turbo-16k",
    text: "GPT 3.5 16k",
  },
  "text-davinci-003": {
    key: "text-davinci-003",
    text: "Davinci",
  },
  "text-ada-001": {
    key: "text-ada-001",
    text: "Ada",
  },
  "text-babbage-001": {
    key: "text-babbage-001",
    text: "Babbage",
  },
  "text-curie-001": {
    key: "text-curie-001",
    text: "Curie",
  },


  "gpt-4": {
    key: "gpt-4",
    text: "GPT 4",
  },
  "gpt-4-chat": {
    key: "gpt-4-chat",
    text: "GPT 4 Chat",
  },
  // "gpt-4-32k": {
  //   key: "gpt-4-32k",
  //   text: "GPT 4 - 32k",
  // },
};

export const DefaultModel = ToolModelType["gpt-3.5-turbo"].key;
export const isChatCheck = model => model === ToolModelType["gpt-3.5-turbo-chat"].key || model === ToolModelType["gpt-4-chat"].key;
export const needsMessages = model => model === ToolModelType["gpt-3.5-turbo"].key ||
  model === ToolModelType["gpt-3.5-turbo-chat"].key ||
  model === ToolModelType["gpt-4"].key ||
  model === ToolModelType["gpt-4-chat"].key ||
  model === ToolModelType["gpt-3.5-turbo-16k"].key;
// || model === ToolModelType["gpt-4-32k"].key;

export const ToolType = {
  individual: {
    key: "individual",
    text: "Individual",
  },
  shareable: {
    key: "shareable",
    text: "Shareable",
  },
  public: {
    key: "public",
    text: "Public",
  },
};

export const ToolCategories = [
  "Personal",
  "Business",
  "Social",
  "Content",
  "Programming",
];
