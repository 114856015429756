import React, { Component } from "react";
import { computed, makeObservable, observable } from "mobx";
import { observer, inject } from "mobx-react";
import TextareaAutosize from "react-textarea-autosize";
import { CheckIcon } from "@heroicons/react/outline";

import CodeEditor from "@uiw/react-textarea-code-editor";
import EntryDropdown from "./EntryDropdown";

import {
  checkIfConditionalOutput,
  checkIfHeading,
  checkIfNumberValue,
  checkIfOutputNumberValue,
  checkIfOutputSelect,
  checkIfSelect,
} from "../NewTool/helper";

@inject("store")
@observer
class EntryInput extends Component {
  @observable promptLocal;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    if (this.props.updateValue) this.promptLocal = observer(this.props.prompt);
  }

  @computed get prompt() {
    return this.props.prompt;
  }

  onChange = async e => {
    if (this.props.updateValue) {
      this.promptLocal.value = e.target.value;
      this.props.updateValue(this.props.prompt.attr, e.target.value);
    } else {
      this.props.prompt.value = e.target.value;
    }
    this.prompt.error = "";
  };

  onChangeDropdown = async e => {
    this.props.prompt.value = e;
    if (this.props.updateValue) {
      this.props.updateValue(this.props.prompt.attr, e);
    }
    this.props.prompt.error = "";
  };

  // handleEditorChange = (e, value) => {
  // 	console.log(e)
  // 	this.props.prompt.value = e.target.value
  // 	this.props.prompt.error = ""
  // };

  @computed get isMinLength() {
    const usedPrompt = this.props.updateValue
      ? this.promptLocal
      : this.props.prompt;

    if (!usedPrompt.min) {
      return false;
    }
    if (!usedPrompt.type === "number" || !usedPrompt.type === "output-number") {
      return false;
    }
    if (usedPrompt.value.length === 0) {
      return false;
    }
    if (usedPrompt.value.length < usedPrompt.min) {
      return true;
    }
    return false;
  }

  @computed get isLongEnoughToDrop() {
    return this.props.updateValue
      ? this.promptLocal.value < 30
      : this.props.prompt.value.length < 30;
  }

  @computed get currentNumberOfCharacters() {
    const usedPrompt = this.props.updateValue
      ? this.promptLocal
      : this.props.prompt;

    if (!usedPrompt || !usedPrompt.value) return "";
    const currentCharacters = usedPrompt.value.length;
    let maxCharacters = 80;
    if (usedPrompt.type === "textarea") {
      maxCharacters = 400;
    }
    if (usedPrompt.maxLength) {
      maxCharacters = usedPrompt.maxLength;
    }
    if (usedPrompt.max) {
      maxCharacters = usedPrompt.max;
    }

    return `${currentCharacters}/${maxCharacters}`;
  }

  render() {
    const { Icon } = this.props;
    if (this.props.updateValue && !this.promptLocal) return null;

    const options = !Array.isArray(this.props.prompt?.options)
      ? this.props.prompt?.options
          ?.split(",")
          .map(opt => ({ value: opt, label: opt }))
      : this.props.prompt?.options?.map(e => ({
          value: e.value,
          label: e.value,
        }));

    return (
      <div className="mb-3">
        {this.props.prompt.title && !checkIfHeading(this.props.prompt.type) && (
          <label htmlFor={this.props.prompt.attr} className="form-label">
            {this.props.prompt.title}
          </label>
        )}

        {/* {checkIfSelect(this.props.prompt.type) ||
        checkIfNumberValue(this.props.prompt.type) ||
        checkIfOutputSelect(this.props.prompt.type) ||
        checkIfOutputNumberValue(this.props.prompt.type) ||
        checkIfConditionalOutput(this.props.prompt.type) ||
        !this.currentNumberOfCharacters ? null : (
          <div
            className={
              `
                ${this.isMinLength ? "text-danger" : ""}
                ${!this.isLongEnoughToDrop && this.props.size ? "" : ""}
              `
            }
          >
            {this.currentNumberOfCharacters} chars
          </div>
        )} */}

        {this.props.prompt.type ? null : (
          <input
            name={this.props.prompt.attr}
            id={this.props.prompt.attr}
            maxLength={this.props.prompt.maxLength || 80}
            {...this.props.prompt}
            autoFocus={this.props.index === 0}
            className="form-control"
            onChange={this.onChange}
            placeholder=""
          />
        )}

        {(this.props.prompt.type === "text" ||
          this.props.prompt.type === "output-text") && (
          <input
            name={this.props.prompt.attr}
            id={this.props.prompt.attr}
            maxLength={this.props.prompt.maxLength || 400}
            {...this.props.prompt}
            autoFocus={this.props.index === 0}
            className="form-control"
            onChange={this.onChange}
            value={this.props.prompt.value}
            placeholder=""
          />
        )}

        {(this.props.prompt.type === "number" ||
          this.props.prompt.type === "output-number") && (
          <>
            {this.props.prompt.min && this.props.prompt.max ? (
              <>
                <input
                  name={this.props.prompt.attr}
                  id={this.props.prompt.attr}
                  maxLength={this.props.prompt.maxLength || 100}
                  autoFocus={this.props.index === 0}
                  className="form-control"
                  onChange={this.onChange}
                  value={this.props.prompt.value || this.props.prompt.min}
                  type="range"
                  min={this.props.prompt.min}
                  max={this.props.prompt.max}
                />
                <div className="form-text">
                  Min: {this.props.prompt.min}, Max: {this.props.prompt.max}
                </div>
              </>
            ) : (
              <input
                name={this.props.prompt.attr}
                id={this.props.prompt.attr}
                autoFocus={this.props.index === 0}
                type="number"
                className="form-control"
                onChange={this.onChange}
                value={this.props.prompt.value}
              />
            )}
          </>
        )}

        {(this.props.prompt.type === "dropdown" ||
          this.props.prompt.type === "single-select" ||
          this.props.prompt.type === "multiple-select" ||
          this.props.prompt.type === "output-single-select" ||
          this.props.prompt.type === "output-multiple-select") && (
          <EntryDropdown
            onChange={this.onChangeDropdown}
            options={options}
            value={this.props.prompt.value}
            isMulti={
              this.props.prompt.type === "multiple-select" ||
              this.props.prompt.type === "output-multiple-select"
            }
          />
        )}

        {(this.props.prompt.type === "code" ||
          this.props.prompt.type === "output-code") && (
          <CodeEditor
            language={this.props.language}
            onChange={this.onChange}
            autoFocus={this.props.index === 0}
            name={this.props.prompt.attr}
            id={this.props.prompt.attr}
            {...this.props.prompt}
          />
        )}

        {(this.props.prompt.type === "textarea" ||
          this.props.prompt.type === "output-textarea") && (
          <TextareaAutosize
            autoFocus={this.props.index === 0}
            minRows={this.props.minRows || 2}
            maxRows={10}
            name={this.props.prompt.attr}
            id={this.props.prompt.attr}
            {...this.props.prompt}
            className="form-control"
            onChange={this.onChange}
            placeholder=""
          />
        )}

        {this.props.prompt.type === "radio" && this.props.prompt.options && (
          <>
            {this.props.prompt.options.map((option, index) => (
              <Option
                key={index}
                Icon={option.Icon}
                title={option.title}
                desc={option.desc}
                active={option.value === this.props.prompt.value}
                onClick={() => (this.props.prompt.value = option.value)}
              />
            ))}
          </>
        )}

        {this.props.prompt.type === "h1" && <h1>{this.props.prompt.value}</h1>}

        {this.props.prompt.type === "h2" && <h2>{this.props.prompt.value}</h2>}

        {this.props.prompt.type === "h3" && <h3>{this.props.prompt.value}</h3>}

        {this.props.prompt.type === "p" && <p>{this.props.prompt.value}</p>}

        {this.props.prompt.label && (
          <label htmlFor={this.props.prompt.attr} className="form-label">
            {this.props.prompt.error || this.props.prompt.label}
          </label>
        )}

        {this.props.prompt.error && (
          <div className="text-danger">
            {this.props.prompt.error || this.props.prompt.label}
          </div>
        )}
      </div>
    );
  }
}

function Option({ _index, title, desc, active, onClick, Icon }) {
  return (
    <div className={`p-1 bg-${active ? "primary" : ""}`} onClick={onClick}>
      <div>{title}</div>
      <div>
        <b>{desc}</b>
      </div>
    </div>
  );
}

export default EntryInput;
