import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { computed, observable, makeObservable } from "mobx";
import { Helmet } from "react-helmet-async";
import { observer, inject } from "mobx-react";

import BasicModal from "./Components/BasicModal";
import { Icon } from "./Icons";

@inject("store")
@observer
class Body extends Component {
  @observable showDeleteModal;
  @observable toDeleteToolId;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get permissions() {
    return this.props.store.tools.filter(tool =>
      tool.permissions.some(r =>
        this.props.store.profile.permissions.includes(r)
      )
    );
  }

  toggleShowDeleteTool = (val = null, toolId = null) => {
    if (val !== null) {
      this.showDeleteModal = !this.showDeleteModal;
    } else {
      this.showDeleteModal = val;
    }
    this.toDeleteToolId = toolId;
  };

  async deleteTool() {
    await this.props.store.api.delete(`/tool/${this.toDeleteToolId}`);
    await this.props.store.init();
  }

  render() {
    const { uniqueCategories } = this.props.store;
    return (
      <>
        <Helmet>
          <title>Tools - Vuo AI</title>
        </Helmet>
        <main>
          <div className="tools-list">
            {uniqueCategories.length > 0 &&
              uniqueCategories.map(cat => {
                const list = this.permissions.filter(
                  tool => tool.category === cat
                );
                return (
                  list.length > 0 && (
                    <div key={cat}>
                      {list.map((tool, index) => (
                        <Tool
                          key={index}
                          group={tool.category}
                          title={tool.title}
                          uses={tool.uses}
                          createdBy={tool.createdBy}
                          toolId={tool.id}
                          searchKey={tool.searchKey}
                          to={tool.to}
                          Icon={tool.Icon}
                          toColor={tool.toColor}
                          canEdit={tool.apiCreated && tool.canEdit}
                          setShowModal={this.toggleShowDeleteTool}
                        />
                      ))}
                    </div>
                  )
                );
              })}
          </div>
        </main>
        <Modal
          showModal={this.showDeleteModal}
          setShowModal={(val, id) => this.toggleShowDeleteTool(val, id)}
          action={() => this.deleteTool()}
        />
      </>
    );
  }
}

export const Tool = withRouter(
  ({
    title,
    uses,
    createdBy,
    toolId,
    searchKey,
    to,
    group,
    canEdit,
    history,
    setShowModal,
  }) => (
    <div className="card mb-2">
      <div className="card-body">
        <div className="hstack gap-1">
          <div className="flex-grow-1">
            <h3 className="card-title fs-5">{`${title}`}</h3>
            <div className="hstack gap-3">
              <div className="badge text-bg-primary">{group}</div>
              <small>by {createdBy}</small>
              {/* <small className="opacity-50">
                {`${uses > 0 ? uses : "No"} ${uses === 1 ? "run" : "runs"}`}
              </small> */}
            </div>
          </div>
          <div className="hstack align-items-center gap-1">
            {canEdit && (
              <button
                className="btn btn-light"
                onClick={e => {
                  e.preventDefault();
                  history.push(`/edit-tool/${createdBy}/${searchKey}`);
                }}
              >
                <Icon name="pencil-square"></Icon>
                &nbsp; Edit
              </button>
            )}
            <Link to={to} className="btn btn-light">
              <Icon name="play-fill"></Icon>
              &nbsp; Use
            </Link>
            <div className="me-1"></div>
            {canEdit && (
              <button
                className="btn btn-light"
                onClick={e => {
                  e.preventDefault();
                  setShowModal(true, toolId);
                }}
              >
                <Icon name="trash3-fill"></Icon>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
);

const Modal = observer(({ showModal, setShowModal, action }) => (
  <BasicModal
    title="Are you sure you want to delete the tool?"
    showModal={showModal}
    setShowModal={setShowModal}
    action={action}
    yesText="Delete"
    noText="Cancel"
    hasBackground
  />
));

export default Body;
