import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { observable, makeObservable, computed } from "mobx";
import { Icon } from "../Icons";
import { ToolModelType, isChatCheck } from "../NewTool/helper";
import config from "../config";

const { v4: uuidv4 } = require("uuid");

@inject("store")
@observer
class ShareModal extends Component {
  @observable rangeValue;
  @observable tokenId;
  @observable userCredits;
  @observable links;
  @observable canUseUserCredits = false;

  @observable codeLink = "";
  @observable apiLink = "";
  @observable apiKey;
  @observable apiBody = {};

  @observable editingLink = "";
  editingValue = -1;
  eventListener;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.rangeValue = 50;
    this.tokenId = uuidv4();
    this.userCredits = +this.props.store.profile.credits;
  }

  async componentDidMount() {
    await this.refresh();
    this.eventListener = document.addEventListener("keydown", e => {
      if (e.key === "Escape") {
        this.props.setShowModal(false);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.eventListener);
  }

  onChangeRangeValue = val => {
    if (+val < 1) return;
    this.rangeValue = +val;
  };

  onChangeCanUseCreditsValue = val => {
    this.canUseUserCredits = val;
  };

  @computed get showModal() {
    return this.props.showModal;
  }

  @computed get isChat() {
    return Object.values(this.props.tool.aiPrompts).some(el =>
      isChatCheck(el.model)
    );
  }

  async addLink() {
    await this.props.store.api.post("/tool/tool-token", {
      tokenId: this.tokenId,
      tool: this.props.tool.id,
      credits: this.rangeValue,
      canUseUserCredits: this.canUseUserCredits,
    });
    await this.refresh();
    this.tokenId = uuidv4();
  }

  async updateLink() {
    await this.props.store.api.post(`/tool/tool-token/${this.editingLink}`, {
      credits: this.editingValue,
    });
    this.editingLink = "";
    await this.refresh();
  }

  async removeLink(token) {
    await this.props.store.api.delete(`/tool/tool-token/${token}`);
    await this.refresh();
    this.apiLink = "";
    this.tokenId = uuidv4();
  }

  async refresh() {
    this.links = await this.props.store.api
      .get(`/tool/tool-token/${this.props.tool.id}`)
      .then(({ data }) => data);
  }

  render() {
    return (
      <>
        {this.showModal ? (
          <div
            className="modal modal-xl background-modal"
            style={{ display: "block" }}
            onClick={() => {
              this.props.setShowModal(false);
            }}
          >
            <div
              className="modal-dialog"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title">Share {this.props.tool.title}</h3>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => this.props.setShowModal(false)}
                  >
                    {" "}
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card mb-4">
                    <div className="col-12">
                      <div className="card-body">
                        <h4>New link</h4>
                        <div className="mb-3">
                          <label htmlFor="credits" className="form-label">
                            Amount of credits the link can use
                          </label>
                          <input
                            name="credits"
                            id="credits"
                            type="number"
                            style={{ width: "6rem" }}
                            className="form-control"
                            disabled={this.canUseUserCredits}
                            value={this.rangeValue}
                            min="1"
                            onChange={e =>
                              this.onChangeRangeValue(e.target.value)
                            }
                          />
                        </div>
                        <div className="form-check mb-2">
                          <input
                            type="checkbox"
                            id="use-credits"
                            name="use-credits"
                            className="form-check-input"
                            value={this.canUseUserCredits}
                            onChange={e =>
                              this.onChangeCanUseCreditsValue(e.target.checked)
                            }
                          />
                          <label className="form-label" htmlFor="use-credits">
                            Allow link to use all my credits
                          </label>
                        </div>
                        <div>
                          <button
                            className="btn btn-primary"
                            onClick={() => this.addLink()}
                          >
                            <Icon name="plus-lg" />
                            &nbsp; Add shared link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h4>Active links</h4>

                      {this.links?.map(el => (
                        <div
                          className="card card-body mb-4"
                          key={Math.random()}
                        >
                          <div className="mb-2">
                            <div className="hstack gap-1">
                              {el.tokenId !== this.editingLink && (
                                <>
                                  {el.canUseUserCredits
                                    ? this.userCredits
                                    : el.credits}{" "}
                                  {}
                                  credits available
                                  <button
                                    className="btn btn-sm btn-light"
                                    disabled={el.tokenId === this.editingLink}
                                    onClick={() => {
                                      this.editingLink = el.tokenId;
                                      this.editingValue = el.canUseUserCredits
                                        ? this.userCredits
                                        : el.credits;
                                    }}
                                  >
                                    <Icon name="pencil-fill" />
                                  </button>
                                  <div className="flex-grow-1" />
                                </>
                              )}
                              {el.tokenId === this.editingLink && (
                                <div className="hstack gap-1">
                                  <input
                                    type="number"
                                    style={{ width: "5rem" }}
                                    defaultValue={this.editingValue}
                                    id="edit-credits-input"
                                    className="form-control form-control-sm"
                                    onChange={e => {
                                      this.editingValue = e.target.value;
                                    }}
                                  />{" "}
                                  {}
                                  <label
                                    className="me-1"
                                    htmlFor="edit-credits-input"
                                  >
                                    credits
                                  </label>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {
                                      this.updateLink();
                                    }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {
                                      this.editingLink = "";
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                              <div className="flex-grow-1" />
                              <button
                                className="btn btn-sm btn-light"
                                onClick={() => this.removeLink(el.tokenId)}
                              >
                                <Icon name="x-lg" />
                                &nbsp; Remove link
                              </button>
                            </div>
                          </div>
                          <div className="mb-2">
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={`${this.props.store.domain}${
                                this.isChat ? "tools-chat" : "tools"
                              }/${el.tokenId}`}
                              disabled
                            />
                          </div>
                          <div className="hstack mb-2 gap-1">
                            <button
                              className="btn btn-sm btn-primary text-nowrap"
                              onClick={() => {
                                this.props.store.copyToClipboard(
                                  `${this.props.store.domain}${
                                    this.isChat ? "tools-chat" : "tools"
                                  }/${el.tokenId}`
                                );
                              }}
                            >
                              <Icon name="link" />
                              &nbsp; Copy link
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                this.codeLink = `${this.props.store.domain}${
                                  this.isChat ? "tools-chat" : "tools"
                                }/${el.tokenId}`;
                                this.props.store.copyToClipboard(
                                  `<iframe src="${this.codeLink}" title="${this.props.tool.title}" width="800" height="1200"></iframe>`
                                );
                              }}
                            >
                              <Icon name="code-square" />
                              &nbsp; Copy iFrame embed code
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                this.apiLink = `${config.backend}public-tools/tools/tools/${this.props.tool.createdBy}/${el.tokenId}`;
                                const defaultKey = Object.keys(
                                  this.props.tool.aiPrompts
                                )[0];
                                this.apiKey = defaultKey;
                                this.apiBody = {};
                                this.apiBody.currentPrompt = defaultKey;
                                this.apiBody.tokenId = el.tokenId;
                                this.props.tool.aiPrompts[
                                  defaultKey
                                ].variables.forEach(variable => {
                                  this.apiBody[variable] = `<${variable}>`;
                                });
                              }}
                            >
                              <Icon name="braces" />
                              &nbsp; Show API
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-4">
                      <h4>API</h4>
                      {this.apiLink ? (
                        <>
                          {Object.keys(this.props.tool.aiPrompts).length >
                            1 && (
                            <div className="mb-3">
                              <label
                                className="form-label"
                                htmlFor="select-api-key"
                              >
                                API prompt
                              </label>
                              <select
                                id="select-api-key"
                                className="form-select"
                                value={this.apiKey}
                                onChange={e => {
                                  this.apiKey = e.target.value;
                                  const token = this.apiBody.tokenId;
                                  this.apiBody = {};
                                  this.apiBody.currentPrompt = this.apiKey;
                                  this.apiBody.tokenId = token;
                                  this.props.tool.aiPrompts[
                                    this.apiKey
                                  ].variables.forEach(variable => {
                                    this.apiBody[variable] = `<${variable}>`;
                                  });
                                }}
                              >
                                {Object.keys(this.props.tool.aiPrompts).map(
                                  key => (
                                    <option key={key} value={key}>
                                      {key}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          )}

                          <label
                            className="form-label"
                            htmlFor="api-link text-nowrap"
                          >
                            URL
                          </label>
                          <div className="hstack gap-1">
                            <input
                              name="api link"
                              id="api-link"
                              className="form-control"
                              disabled
                              defaultValue={this.apiLink}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="">
                              Body
                            </label>
                            <CodeEditor
                              style={{}}
                              className="form-control"
                              language="javascript"
                              value={JSON.stringify(this.apiBody)
                                .replace(/,/g, ",\n")
                                .replace("{", "{\n")
                                .replace("}", "\n}")}
                            />
                          </div>
                          <div className="hstack gap-2">
                            <button
                              className="btn btn-primary text-nowrap"
                              onClick={() =>
                                this.props.store.copyToClipboard(this.apiLink)
                              }
                            >
                              Copy URL
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                this.props.store.copyToClipboard(
                                  JSON.stringify(this.apiBody)
                                    .replace(/,/g, ",\n")
                                    .replace("{", "{\n")
                                    .replace("}", "\n}")
                                )
                              }
                            >
                              Copy Body
                            </button>
                          </div>
                        </>
                      ) : (
                        <>Click Show API on a link to see API details.</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default ShareModal;
