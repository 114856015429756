import React, { Component } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class EntryPrompt extends Component {
  render() {
    const { currentPrompt, disabled, index, prompt } = this.props;
    const hidden = currentPrompt !== index;

    return (
      <div className="card shadow">
        <h3 className="card-header">
          {/* TODO: this is actually the tool name, not prompt title */}
          {prompt.title || ""}
        </h3>
        <div className="card-body">{this.props.children}</div>
      </div>
    );
  }
}

export default EntryPrompt;
