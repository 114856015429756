import React, { Component } from "react";
import { observable, computed, makeObservable } from "mobx";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
  checkIfHeading,
  checkIfValue,
  ToolModelType,
  ToolType,
  UITypes,
  ToolCategories,
  DefaultModel,
  checkIfOutputValue,
  checkIfSubmit,
  checkIfOutputSubmit,
  checkIfOutputAsValue,
  checkIfOutput,
  checkIfNumberValue,
  checkIfSelect,
  checkIfOutputNumberValue,
  checkIfOutputSelect,
  checkIfOutputs,
  checkIfConditionalOutput,
  isChatCheck,
  needsMessages,
} from "./helper";
import Header from "../Components/Header";
import Models from "../Models";
import ToolPreview from "../Core/ToolPreview";
import ToolHistory from "../Core/ToolHistory";
import Autocomplete from "../Components/Autocomplete";
import TestModal from "../Core/TestModal";
import { Icon } from "../Icons";
import ShareModal from "../Core/ShareModal";
import BasicModal from "../Components/BasicModal";

const { NewTool } = Models;

@inject("store")
@observer
class Body extends Component {
  @observable tool;

  @observable selection;

  @observable editMode = false;
  @observable error;

  @observable showTestModal = false;
  @observable isSharingOpen = false;
  @observable isNavigationOpen = false;

  @observable currentPrompt = 0;
  @observable showSavedText = false;

  dirty = false;
  uiChanges = 0;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    this.props.store.refreshTokenAndProfile();

    const toolParam = this.props.match.params.tool;
    const userParam = this.props.match.params.user;
    this.editMode = !!toolParam;

    if (this.editMode) {
      const editedTool = this.props.store.getToolByTitle(toolParam, userParam);
      if (editedTool) {
        this.tool = { ...NewTool.fromTool(editedTool) };
      } else {
        const tools = await this.props.store.getTools();
        const newEditedTool = tools?.find(
          tool => tool.searchKey === toolParam && tool.createdBy === userParam
        );
        const newTool = NewTool.fromTool(newEditedTool);
        this.tool = { ...newTool };
      }
      this.originalTitle = this.tool.title;
    } else {
      const newTool = NewTool.init();
      this.tool = { ...newTool };
    }
  }

  onChangeToolName(val) {
    this.tool.toolName = val;
    this.enableDirty();
    this.uiChanges++;
  }

  onChangeToolType(val) {
    this.tool.toolType = val;
    this.enableDirty();
  }

  onChangeToolCategory(val) {
    this.tool.toolCategory = val;
    this.enableDirty();
  }

  onChangePromptModel(val, idx) {
    this.tool.prompts[idx].model = val;
    this.enableDirty();
  }

  onChangePromptOptions(key, val, idx) {
    this.tool.prompts[idx].options[key] = +val;
    this.enableDirty();
  }

  onChangeShowPromptOptions(idx) {
    this.tool.prompts[idx].showOptions = !this.tool.prompts[idx].showOptions;
  }

  onChangeUseContext() {
    this.tool.useContext = !this.tool.useContext;
    this.enableDirty();
  }

  onChangePromptKey(val, idx) {
    const oldKey = this.tool.prompts[idx].key;
    this.tool.uiStructure.forEach(el => {
      el.condition = el.condition.replace(oldKey, val);
      el.uiValue = el.uiValue.replace(oldKey, val);
    });

    this.tool.prompts[idx].key = val;
    this.enableDirty();
  }

  onChangePromptRole(val, idx) {
    this.tool.prompts[idx].role = val;
    this.enableDirty();
  }

  onChangeIntroMessage(val) {
    this.tool.introMessage = val;
    this.enableDirty();
  }

  @computed get combinedVariables() {
    const combinedVariables = this.tool.prompts?.reduce((r, val) => {
      const vars = val.variables;

      vars?.forEach(v => {
        r.push({
          promptKey: val.key,
          text: `${v} [${val.key}]`,
          promptVariable: v,
        });
      });

      return r;
    }, []);
    return combinedVariables;
  }

  @computed get availableUITypes() {
    return this.combinedVariables && this.combinedVariables.length
      ? Object.values(UITypes)
      : Object.values(UITypes).filter(
          el =>
            !checkIfValue(el.key) &&
            !checkIfOutputValue(el.key) &&
            !checkIfOutputAsValue(el.key) &&
            !checkIfNumberValue(el.key) &&
            !checkIfSelect(el.key) &&
            !checkIfOutputNumberValue(el.key) &&
            !checkIfOutputSelect(el.key) &&
            !checkIfConditionalOutput(el.key)
        );
  }

  @computed get defaultUIType() {
    return this.availableUITypes && this.availableUITypes.length
      ? this.availableUITypes[0].key
      : null;
  }

  @computed get promptKeys() {
    return this.tool.prompts.map(p => p.key);
  }

  onChangePrompt = (val, selection, idx) => {
    if (selection) {
      this.selection =
        selection.start === selection.end
          ? ""
          : val.substr(selection.start, selection.end - selection.start);
    }
    this.tool.prompts[idx].prompt = val;
    this.enableDirty();
  };

  addVariable = () => {
    this.tool.prompts[this.currentPrompt].variables = [
      ...this.tool.prompts[this.currentPrompt].variables,
      this.selection,
    ];
    this.tool = { ...this.tool, prompts: [...this.tool.prompts] };
    this.addUIElement(
      `${this.selection} [${this.tool.prompts[this.currentPrompt].key}]`
    );
    this.enableDirty();
  };

  removeVariable = word => {
    this.tool.prompts[this.currentPrompt].variables = this.tool.prompts[
      this.currentPrompt
    ].variables.filter(val => val !== word);
    this.tool = { ...this.tool, prompts: [...this.tool.prompts] };
    this.enableDirty();
  };

  addUIElement = el => {
    const key = Math.random();
    this.tool = {
      ...this.tool,
      uiStructure: [
        ...this.tool.uiStructure,
        {
          uiType: this.defaultUIType,
          uiValue:
            el ||
            (this.combinedVariables.length
              ? this.combinedVariables[0].text
              : null),
          key,
          condition: this.promptKeys[0],
        },
      ],
    };
    this.enableDirty();
    this.uiChanges++;
  };

  removeUIElement = key => {
    this.tool = {
      ...this.tool,
      uiStructure: this.tool.uiStructure.filter(val => val.key !== key),
    };
    this.enableDirty();
    this.uiChanges++;
  };

  addPrompt = () => {
    this.tool = {
      ...this.tool,
      prompts: [
        ...this.tool.prompts,
        {
          key: `prompt${this.tool.prompts.length + 1}`,
          model: DefaultModel,
          options: {
            maxTokens: 1024,
            bestOf: 1,
            topP: 1,
            frequencyPenalty: 1,
            presencePenalty: 0,
            n: 1,
            temperature: 0.2,
          },
          variables: [],
        },
      ],
    };
    this.currentPrompt = this.tool.prompts.length - 1;
    this.enableDirty();
  };

  removePrompt = () => {
    this.tool.prompts = this.tool.prompts.filter(
      (_el, idx) => idx !== this.currentPrompt
    );
    this.currentPrompt = 0;
    this.enableDirty();
  };

  incrementPrompt = (positive = true) => {
    if (positive) {
      this.currentPrompt += 1;
      if (this.currentPrompt === this.tool.prompts.length)
        this.currentPrompt = 0;
    }
    if (!positive) {
      this.currentPrompt -= 1;
      if (this.currentPrompt === -1)
        this.currentPrompt = this.tool.prompts.length - 1;
    }
  };

  changeUIElementType = (val, idx) => {
    this.tool.uiStructure[idx].uiType = val;
    if (checkIfHeading(val) || checkIfOutput(val))
      this.tool.uiStructure[idx].uiValue = "";
    if (
      checkIfSubmit(val) ||
      checkIfOutputSubmit(val) ||
      checkIfConditionalOutput(val)
    ) {
      const initialValue = this.promptKeys[0];
      this.tool.uiStructure[idx].uiValue = initialValue;
    }
    if (checkIfOutputs[val]) {
      const initialValue = this.promptKeys[0];
      this.tool.uiStructure[idx].condition = initialValue;
    }
    this.tool = { ...this.tool, uiStructure: this.tool.uiStructure };
    this.enableDirty();
  };

  changeUIElementValue = (val, idx) => {
    this.tool.uiStructure[idx].uiValue = val;
    this.enableDirty();
  };

  changeUIElementOptions = (val, idx) => {
    this.tool.uiStructure[idx].uiOptions = val;
    this.enableDirty();
  };

  changeUIMinMaxOptions = (val, idx, type = "min") => {
    if (!val.match(/^\d+\.?\d*$/)) return;
    if (type === "min") {
      val = Math.max(0, val);
      val = Math.min(this.tool.uiStructure[idx].max || 0, val);
      this.tool.uiStructure[idx].min = val;
    } else {
      val = Math.max(0, +val);
      val = Math.max(this.tool.uiStructure[idx].min || 0, val);
      this.tool.uiStructure[idx].max = val;
    }
    this.enableDirty();
  };

  changeUIElementText = (val, idx) => {
    this.tool.uiStructure[idx].uiText = val;
    this.enableDirty();
  };

  changeUIElementTitle = (val, idx) => {
    this.tool.uiStructure[idx].title = val;
    this.enableDirty();
  };

  changeUIElementCondition = (val, idx) => {
    this.tool.uiStructure[idx].condition = val;
    this.enableDirty();
  };

  changeOutputType = (val, idx) => {
    this.tool.uiStructure[idx].outputType = val;
    this.enableDirty();
  };

  createOrEditTool = async () => {
    if (!this.tool.toolName) {
      this.error = "Required tool name.";
      return;
    }
    this.error = null;
    if (!this.editMode) {
      await this.props.store.api
        .post("/tool/create-tool", {
          title: this.tool.toolName,
          category: this.tool.toolCategory,
          type: this.tool.toolType || ToolType.public.key,
          introMessage: this.tool.introMessage,
          prompts: this.tool.prompts,
          uiStructure: this.tool.uiStructure,
          historyId: this.tool.historyId,
          useContext: this.tool.useContext,
        })
        .then(async res => {
          console.log("Created Tool!", res);

          if (res.data.success) {
            const newTools = await this.props.store.getTools();
            if (this.props.store.isCreator) await this.props.store.getMyTools();

            const searchKey = this.tool.toolName
              .toLowerCase()
              .replace(/([^a-zA-z0-9]+)/g, _s0 => "-");
            const editedTool = newTools.find(
              el =>
                el.searchKey === searchKey &&
                el.createdBy === this.props.store.profile.username
            );
            const newTool = NewTool.fromTool(editedTool);
            this.tool = observer(newTool);
            this.editMode = true;
            this.dirty = false;
            this.showSavedText = true;
          } else {
            this.error = res.data.error;
          }
        });
    } else {
      await this.props.store.api
        .post("/tool/update-tool", {
          title: this.tool.toolName,
          category: this.tool.toolCategory,
          type: this.tool.toolType,
          introMessage: this.tool.introMessage,
          prompts: this.tool.prompts,
          uiStructure: this.tool.uiStructure,
          searchKey: this.tool.searchKey,
          historyId: this.tool.historyId,
          userId: this.tool.userId,
          useContext: this.tool.useContext,
        })
        .then(async res => {
          console.log("Updated Tool!", res);

          if (res.data.success) {
            const newTools = await this.props.store.getTools();
            if (this.props.store.isCreator) await this.props.store.getMyTools();

            const searchKey = this.tool.toolName
              .toLowerCase()
              .replace(/([^a-zA-z0-9]+)/g, _s0 => "-");
            const editedTool = newTools.find(
              el =>
                el.searchKey === searchKey &&
                el.createdBy ===
                  (this.tool.createdBy || this.props.store.profile.username)
            );
            const newTool = NewTool.fromTool(editedTool);
            this.tool = observer(newTool);
            this.dirty = false;
            this.showSavedText = true;
          } else {
            this.error = res.data.error;
          }
        });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.tool = {
      ...this.tool,
      uiStructure: arrayMoveImmutable(
        this.tool.uiStructure,
        oldIndex,
        newIndex
      ),
    };
  };

  revertTool = exTool => {
    this.tool.toolName = exTool.title;
    this.tool.toolType = exTool.type;
    this.tool.prompts = exTool.prompts;
    this.tool.uiStructure = [...exTool.uiStructure];
    this.tool.searchKey = exTool.searchKey;
    this.tool.historyId = exTool.historyId;
    this.tool.introMessage = exTool.introMessage;
  };

  changeShowTestModal = (val = null) => {
    if (val !== null) {
      this.showTestModal = val;
    } else {
      this.showTestModal = !this.showTestModal;
    }
  };

  pickedPolishedPrompt = (prompt, model, variables, role) => {
    this.tool.prompts[this.currentPrompt].prompt = prompt;
    this.tool.prompts[this.currentPrompt].model = model;
    this.tool.prompts[this.currentPrompt].role = role;
    this.tool.prompts[this.currentPrompt].variables = variables;
    this.enableDirty();
  };

  toggleSharingModal = (val = null) => {
    if (val === null) {
      this.isSharingOpen = !this.isSharingOpen;
    } else {
      this.isSharingOpen = val;
    }
  };

  toggleNavigationModal = (val = null) => {
    if (val === null) {
      this.isNavigationOpen = !this.isNavigationOpen;
    } else {
      this.isNavigationOpen = val;
    }
  };

  saveAndNavigate = async () => {
    this.toggleNavigationModal(false);
    await this.createOrEditTool();
    this.props.history.push(this.tool.to);
  };

  navigate = async () => {
    this.toggleNavigationModal(false);
    this.props.history.push(this.tool.to);
  };

  enableDirty() {
    this.dirty = true;
  }

  updateHistory() {
    this.tool.historyVersion += 1;
  }

  render() {
    if (!this.tool) return null;
    const canChangePrompts = this.tool.prompts.length > 1;

    return (
      <>
        <Header
          title={this.tool.toolName || "New tool"}
          selected={this.editMode ? "edit" : "new"}
          shareFc={() => this.toggleSharingModal()}
          toUse={() => {
            if (this.dirty) this.toggleNavigationModal(true);
            else this.navigate();
          }}
          canEdit
          getAnalytics={() => this.props.store.getAnalytics(this.tool.id)}
        />
        <main>
          <div className="container">
            <div className="row">
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <label htmlFor="tool-name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      id="tool-name"
                      required
                      className="form-control"
                      onChange={e => this.onChangeToolName(e.target.value)}
                      defaultValue={this.tool.toolName}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="tool-category" className="form-label">
                      Category
                    </label>
                    {this.props.store.isAdmin ? (
                      <Autocomplete
                        id="tool-category"
                        suggestions={this.props.store.uniqueCategories}
                        inputCb={val => this.onChangeToolCategory(val)}
                        initialValue={
                          this.tool.toolCategory || ToolCategories[0]
                        }
                      />
                    ) : (
                      <select
                        id="tool-category"
                        className="form-control"
                        defaultValue={
                          this.tool.toolCategory || ToolCategories[0]
                        }
                        onChange={e =>
                          this.onChangeToolCategory(e.target.value)
                        }
                      >
                        {Object.values(ToolCategories).map(cat => (
                          <option key={cat} value={cat}>
                            {cat}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-4">
                    <label className="form-label">Type</label>
                    <select
                      id="select-tool-prompt"
                      className="form-control"
                      defaultValue={this.tool.toolType || ToolType.public.key}
                      onChange={e => this.onChangeToolType(e.target.value)}
                    >
                      {Object.values(ToolType).map(({ key, text }) => (
                        <option key={key} value={key}>
                          {text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="pb-3" />

                <div className="row">
                  <div className="col-4">
                    <input
                      type="checkbox"
                      id="tool-context"
                      className="form-check-input me-2"
                      checked={this.tool.useContext}
                      onChange={() => this.onChangeUseContext()}
                    />
                    <label htmlFor="tool-context" className="form-check-label">
                      Use Context
                    </label>
                  </div>
                </div>

                <div className="pb-5" />

                <h2>Prompts</h2>
                <nav aria-label="Prompts pagination">
                  <div className="hstack align-items-center gap-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.incrementPrompt(false)}
                    >
                      <Icon name="caret-left-fill" />
                    </button>
                    <h3 className="fs-5 m-0">
                      Prompt {}
                      {this.currentPrompt + 1}
                      {} of {}
                      {this.tool.prompts.length}
                    </h3>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.incrementPrompt()}
                    >
                      <Icon name="caret-right-fill" />
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.addPrompt()}
                    >
                      <Icon name="plus-lg" />
                      &nbsp; Add prompt
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.changeShowTestModal(true)}
                    >
                      <Icon name="stars" />
                      &nbsp; Polish prompt
                    </button>

                    <button
                      className={`btn btn-danger ${
                        this.tool.prompts.length <= 1 ? "disabled" : ""
                      }`}
                      disabled={this.tool.prompts.length <= 1}
                      onClick={() => this.removePrompt()}
                    >
                      <Icon name="x" />
                      &nbsp; Delete prompt
                    </button>
                  </div>
                </nav>

                <div className="pb-3" />

                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-6">
                        <label htmlFor="prompt-key" className="form-label">
                          ID
                        </label>
                        <input
                          type="text"
                          id="prompt-key"
                          className="form-control"
                          onChange={e =>
                            this.onChangePromptKey(
                              e.target.value,
                              this.currentPrompt
                            )
                          }
                          defaultValue={
                            this.tool.prompts[this.currentPrompt].key
                          }
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="prompt-model" className="form-label">
                          Model
                        </label>
                        <div className="row align-items-center">
                          <div className="col-10">
                            <select
                              id="prompt-model"
                              className="form-select"
                              defaultValue={
                                this.tool.prompts[this.currentPrompt].model
                              }
                              onChange={e =>
                                this.onChangePromptModel(
                                  e.target.value,
                                  this.currentPrompt
                                )
                              }
                            >
                              {Object.values(ToolModelType).map(
                                ({ key, text }) => (
                                  <option key={key} value={key}>
                                    {text}
                                  </option>
                                )
                              )}
                            </select>
                          </div>

                          <div className="col-2 -mx-2">
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                this.onChangeShowPromptOptions(
                                  this.currentPrompt
                                )
                              }
                            >
                              <Icon name="sliders" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.tool.prompts[this.currentPrompt].showOptions && (
                      <>
                        <div className="row mb-3">
                          <div className="col-4">
                            <label
                              htmlFor="prompt-max-tokens"
                              className="form-label"
                            >
                              Max Tokens
                            </label>
                            <input
                              type="number"
                              id="prompt-max-tokens"
                              className="form-control"
                              defaultValue={
                                this.tool.prompts[this.currentPrompt].options
                                  ?.maxTokens
                              }
                              onChange={e =>
                                this.onChangePromptOptions(
                                  "maxTokens",
                                  e.target.value,
                                  this.currentPrompt
                                )
                              }
                            />
                          </div>
                          <div className="col-4">
                            <label
                              htmlFor="prompt-best-of"
                              className="form-label"
                            >
                              Best of
                            </label>
                            <input
                              type="number"
                              id="prompt-best-of"
                              className="form-control"
                              defaultValue={
                                this.tool.prompts[this.currentPrompt].options
                                  ?.bestOf
                              }
                              onChange={e =>
                                this.onChangePromptOptions(
                                  "bestOf",
                                  e.target.value,
                                  this.currentPrompt
                                )
                              }
                            />
                          </div>
                          <div className="col-4">
                            <label
                              htmlFor="prompt-top-p"
                              className="form-label"
                            >
                              Top P
                            </label>
                            <input
                              type="number"
                              id="prompt-top-p"
                              className="form-control"
                              defaultValue={
                                this.tool.prompts[this.currentPrompt].options
                                  ?.topP
                              }
                              onChange={e =>
                                this.onChangePromptOptions(
                                  "topP",
                                  e.target.value,
                                  this.currentPrompt
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-4">
                            <label
                              htmlFor="prompt-freq-penalty"
                              className="form-label"
                            >
                              Frequency Penalty
                            </label>
                            <input
                              type="number"
                              id="prompt-freq-penalty"
                              className="form-control"
                              defaultValue={
                                this.tool.prompts[this.currentPrompt].options
                                  ?.frequencyPenalty
                              }
                              onChange={e =>
                                this.onChangePromptOptions(
                                  "frequencyPenalty",
                                  e.target.value,
                                  this.currentPrompt
                                )
                              }
                            />
                          </div>
                          <div className="col-4">
                            <label
                              htmlFor="prompt-presence-penalty"
                              className="form-label"
                            >
                              Presence Penalty
                            </label>
                            <input
                              type="number"
                              id="prompt-presence-penalty"
                              className="form-control"
                              defaultValue={
                                this.tool.prompts[this.currentPrompt].options
                                  ?.presencePenalty
                              }
                              onChange={e =>
                                this.onChangePromptOptions(
                                  "presencePenalty",
                                  e.target.value,
                                  this.currentPrompt
                                )
                              }
                            />
                          </div>
                          <div className="col-4">
                            <label htmlFor="prompt-n" className="form-label">
                              N
                            </label>
                            <input
                              type="number"
                              id="prompt-n"
                              className="form-control"
                              defaultValue={
                                this.tool.prompts[this.currentPrompt].options?.n
                              }
                              onChange={e =>
                                this.onChangePromptOptions(
                                  "n",
                                  e.target.value,
                                  this.currentPrompt
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-6">
                            <label
                              htmlFor="prompt-temperature"
                              className="form-label"
                            >
                              {`Temperature [${
                                this.tool.prompts[this.currentPrompt].options
                                  ?.temperature
                              }]`}
                            </label>
                            <input
                              type="range"
                              className="form-range"
                              min="0"
                              max="1"
                              step="0.1"
                              id="prompt-temperature"
                              defaultValue={
                                this.tool.prompts[this.currentPrompt].options
                                  ?.temperature
                              }
                              onChange={e =>
                                this.onChangePromptOptions(
                                  "temperature",
                                  e.target.value,
                                  this.currentPrompt
                                )
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {needsMessages(
                      this.tool.prompts[this.currentPrompt].model
                    ) && (
                      <div className="mb-3">
                        <label className="form-label">System Role</label>
                        <textarea
                          type="text"
                          id="tool-role"
                          className="form-control"
                          onChange={e =>
                            this.onChangePromptRole(
                              e.target.value,
                              this.currentPrompt
                            )
                          }
                          defaultValue={
                            this.tool.prompts[this.currentPrompt].role
                          }
                          rows={4}
                        />
                      </div>
                    )}

                    {isChatCheck(
                      this.tool.prompts[this.currentPrompt].model
                    ) && (
                      <div className="mb-3">
                        <label htmlFor="tool-role" className="form-label">
                          Intro Message
                        </label>
                        <textarea
                          type="text"
                          id="tool-role"
                          className="form-control"
                          onChange={e =>
                            this.onChangeIntroMessage(e.target.value)
                          }
                          defaultValue={this.tool.introMessage}
                          rows={5}
                        />
                      </div>
                    )}

                    <div className="mb-3">
                      <label className="form-label">Prompt</label>
                      {/* TODO: react-highlight-within-textarea is based on draftjs and that's no longer maintained. Also draftjs works poorly. */}
                      <HighLightArea
                        prompt={this.tool.prompts[this.currentPrompt].prompt}
                        onChangePrompt={(val, sel) =>
                          this.onChangePrompt(val, sel, this.currentPrompt)
                        }
                        variables={
                          this.tool.prompts[this.currentPrompt].variables
                        }
                      />
                    </div>

                    {/* TODO: You should be able to press make variable any time, no need to select text. */}
                    <div className="hstack gap-2">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          if (this.selection) this.addVariable();
                        }}
                      >
                        Make variable
                      </button>
                      {this.tool.prompts[this.currentPrompt].variables?.map(
                        val => (
                          <button
                            key={val}
                            className="btn btn-sm bg-warning-subtle"
                            onClick={() => this.removeVariable(val)}
                          >
                            {val}
                            <Icon name="x" />
                          </button>
                        )
                      )}
                      {this.tool.prompts[this.currentPrompt].variables
                        .length === 0 && (
                        <small>Select text to make it into a variable.</small>
                      )}
                    </div>
                  </div>
                </div>

                <div className="pb-5" />

                <h2>UI Elements</h2>
                <div className="mb-3">
                  {this.tool.uiStructure.length ? (
                    <UIElements
                      uiStructure={this.tool.uiStructure}
                      variables={this.combinedVariables}
                      promptKeys={this.promptKeys}
                      availableUiTypes={this.availableUITypes}
                      defaultUIType={this.defaultUIType}
                      changeUIElementType={this.changeUIElementType}
                      changeUIElementValue={this.changeUIElementValue}
                      changeUIElementText={this.changeUIElementText}
                      changeUIElementTitle={this.changeUIElementTitle}
                      changeUIElementOptions={this.changeUIElementOptions}
                      changeUIMinMaxOptions={this.changeUIMinMaxOptions}
                      changeUIElementCondition={this.changeUIElementCondition}
                      changeOutputType={this.changeOutputType}
                      removeUIElement={this.removeUIElement}
                      onSortEnd={this.onSortEnd}
                    />
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.addUIElement()}
                  >
                    Add UI element
                  </button>
                </div>

                <div className="pb-4" />
                <div className="pb-4" />
              </div>

              <div className="col-4">
                <Preview
                  tool={{ ...this.tool }}
                  updateHistory={() => this.updateHistory()}
                  uiChanges={this.uiChanges}
                />
              </div>
            </div>
          </div>

          <div className="tool-footer">
            <div className="container mt-3 mb-3">
              <div className="hstack gap-3">
                <button
                  className="btn btn-lg btn-primary"
                  onClick={() => this.createOrEditTool()}
                >
                  Save tool
                </button>
                {this.showSavedText && <div>Tool saved!</div>}
                <div className="text-danger">{this.error || ""}</div>
              </div>
            </div>
          </div>

          {/* TODO: move history to stats tab */}
          {/* <History tool={this.tool} revertTool={this.revertTool} /> */}
        </main>
        {this.showTestModal && (
          <Test
            showTestModal={this.showTestModal}
            changeShowTestModal={() => this.changeShowTestModal(false)}
            pickedPolishedPrompt={this.pickedPolishedPrompt}
            prompt={this.tool.prompts[this.currentPrompt].prompt}
            variables={this.tool.prompts[this.currentPrompt].variables}
            role={this.tool.prompts[this.currentPrompt].role}
            model={this.tool.prompts[this.currentPrompt].model}
            useContext={this.tool.useContext}
            options={this.tool.prompts[this.currentPrompt].options}
          />
        )}
        {this.isSharingOpen ? (
          <ShareModal
            showModal={this.isSharingOpen}
            tool={this.tool}
            setShowModal={() => this.toggleSharingModal(false)}
          />
        ) : null}
        {this.isNavigationOpen ? (
          <Modal
            showModal={this.isNavigationOpen}
            setShowModal={() => this.toggleNavigationModal(false)}
            action={() => this.saveAndNavigate()}
            negativeAction={() => this.navigate()}
          />
        ) : null}
      </>
    );
  }
}

const Preview = observer(({ tool, updateHistory, uiChanges }) => (
  <ToolPreview
    tool={tool}
    updateHistory={updateHistory}
    useContext={tool.useContext}
    uiChanges={uiChanges}
  />
));
const History = observer(({ tool, revertTool }) => (
  <ToolHistory historyId={tool.historyId} tool={tool} revertTool={revertTool} />
));

const Test = observer(
  ({
    pickedPolishedPrompt,
    showTestModal,
    changeShowTestModal,
    prompt,
    variables,
    role,
    model,
    useContext,
    options,
  }) => (
    <TestModal
      pickedPolishedPrompt={pickedPolishedPrompt}
      showModal={showTestModal}
      setShowModal={changeShowTestModal}
      initialPrompt={prompt}
      initialVariables={variables}
      initialRole={role}
      initialModel={model}
      useContext={useContext}
      options={options}
    />
  )
);

const HighLightArea = observer(({ prompt, onChangePrompt, variables }) => (
  <HighlightWithinTextarea
    value={prompt || ""}
    onChange={onChangePrompt}
    highlight={variables}
  />
));
const UIElements = ({
  uiStructure,
  variables,
  promptKeys,
  availableUiTypes,
  changeUIElementType,
  changeUIElementValue,
  changeUIElementText,
  changeUIElementTitle,
  changeUIElementOptions,
  changeUIMinMaxOptions,
  changeUIElementCondition,
  removeUIElement,
  onSortEnd,
}) => (
  <SortableList
    onSortEnd={onSortEnd}
    items={uiStructure.map((el, idx) => (
      <div key={`${Math.random()}`} className="card card-ui-element">
        <div className="card-body p-0">
          <div className="hstack">
            <div className="px-1">
              <Icon name="grip-vertical" size="1.5" />
            </div>
            <div className="hstack flex-grow-1 pt-2 pb-3 gap-2">
              <div className="">
                <label className="form-label" htmlFor={`select-${idx}`}>
                  Type
                </label>
                <select
                  className="form-select"
                  defaultValue={el.uiType}
                  onChange={e => changeUIElementType(e.target.value, idx)}
                >
                  {availableUiTypes.map(({ key, text }) => (
                    <option key={key} value={key}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
              {variables.length &&
              (checkIfValue(el.uiType) ||
                checkIfOutputValue(el.uiType) ||
                checkIfSelect(el.uiType) ||
                checkIfNumberValue(el.uiType) ||
                checkIfOutputSelect(el.uiType) ||
                checkIfOutputNumberValue(el.uiType)) ? (
                <>
                  <div>
                    <label
                      className="form-label"
                      htmlFor={`select-value-${idx}`}
                    >
                      Variable
                    </label>
                    <select
                      id={`select-value-${idx}`}
                      className="form-select"
                      defaultValue={
                        variables.find(v => v.text === el.uiValue)?.text ||
                        variables.find(v => v.promptVariable === el.uiValue)
                          ?.text ||
                        variables[0].text
                      }
                      onChange={e => changeUIElementValue(e.target.value, idx)}
                    >
                      {variables.map(val => (
                        <option key={Math.random()} value={val.text}>
                          {val.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="form-label" htmlFor={`text-label-${idx}`}>
                      Label
                    </label>
                    <input
                      type="text"
                      id={`text-label-${idx}`}
                      className="form-control"
                      onChange={e => changeUIElementTitle(e.target.value, idx)}
                      defaultValue={el.title}
                    />
                  </div>
                </>
              ) : !variables.length &&
                (checkIfValue(el.uiType) || checkIfOutputValue(el.uiType)) ? (
                <div className="text-danger">
                  Make a variable in the prompt.
                </div>
              ) : null}
              {checkIfSelect(el.uiType) || checkIfOutputSelect(el.uiType) ? (
                <div>
                  <label className="form-label" htmlFor={`text-options-${idx}`}>
                    Options for select
                  </label>
                  <input
                    type="text"
                    id={`text-options-${idx}`}
                    className="form-control"
                    onChange={e => changeUIElementOptions(e.target.value, idx)}
                    defaultValue={el.uiOptions}
                  />
                </div>
              ) : null}
              {checkIfNumberValue(el.uiType) ||
              checkIfOutputNumberValue(el.uiType) ? (
                <>
                  <div>
                    <label className="form-label" htmlFor={`text-min-${idx}`}>
                      Min
                    </label>
                    <input
                      type="number"
                      id={`text-min-${idx}`}
                      className="form-control"
                      onChange={e =>
                        changeUIMinMaxOptions(e.target.value, idx, "min")
                      }
                      defaultValue={el.min}
                      min="0"
                    />
                  </div>
                  <div>
                    <label className="form-label" htmlFor={`text-max-${idx}`}>
                      Max
                    </label>
                    <input
                      type="number"
                      id={`text-max-${idx}`}
                      className="form-control"
                      onChange={e =>
                        changeUIMinMaxOptions(e.target.value, idx, "max")
                      }
                      defaultValue={el.max}
                      min="0"
                    />
                  </div>
                </>
              ) : null}
              {checkIfHeading(el.uiType) ? (
                <div>
                  <label className="form-label" htmlFor={`text-input-${idx}`}>
                    Text
                  </label>
                  <input
                    type="text"
                    id={`text-input-${idx}`}
                    className="form-control"
                    onChange={e => changeUIElementText(e.target.value, idx)}
                    defaultValue={el.uiText}
                  />
                </div>
              ) : null}
              {promptKeys.length &&
              (checkIfSubmit(el.uiType) || checkIfOutputSubmit(el.uiType)) ? (
                <>
                  <div>
                    <label
                      className="form-label"
                      htmlFor={`select-submit-${idx}`}
                    >
                      Prompt
                    </label>
                    <select
                      id={`select-submit-${idx}`}
                      className="form-control"
                      defaultValue={el.uiValue || promptKeys[0]}
                      onChange={e => changeUIElementValue(e.target.value, idx)}
                    >
                      {promptKeys.map(val => (
                        <option key={Math.random()} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="form-label" htmlFor={`text-label-${idx}`}>
                      Text
                    </label>
                    <input
                      type="text"
                      id={`text-label-${idx}`}
                      className="form-control"
                      onChange={e => changeUIElementTitle(e.target.value, idx)}
                      defaultValue={el.title}
                    />
                  </div>
                </>
              ) : null}
              {variables.length && checkIfOutputAsValue(el.uiType) ? (
                <div>
                  <label className="form-label" htmlFor={`select-value-${idx}`}>
                    Variable
                  </label>
                  <select
                    id={`select-value-${idx}`}
                    className="form-control"
                    defaultValue={
                      variables.find(v => v.text === el.uiValue)?.text ||
                      variables.find(v => v.promptVariable === el.uiValue)
                        ?.text ||
                      variables[0].text
                    }
                    onChange={e => changeUIElementValue(e.target.value, idx)}
                  >
                    {variables.map(val => (
                      <option key={Math.random()} value={val.text}>
                        {val.text}
                      </option>
                    ))}
                  </select>
                </div>
              ) : !variables.length && checkIfOutputAsValue(el.uiType) ? (
                <div className="text-danger">
                  Make a variable in the prompt.
                </div>
              ) : null}
              {checkIfConditionalOutput(el.uiType) ? (
                <div>
                  <label
                    className="form-label"
                    htmlFor={`select-condition-${idx}`}
                  >
                    Prompt
                  </label>
                  <select
                    id={`select-condition-${idx}`}
                    className="form-control"
                    defaultValue={el.uiValue || promptKeys[0]}
                    onChange={e => changeUIElementValue(e.target.value, idx)}
                  >
                    {promptKeys.map(val => (
                      <option key={Math.random()} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {checkIfOutputs(el.uiType) ? (
                <div>
                  <label
                    className="form-label"
                    htmlFor={`select-condition-${idx}`}
                  >
                    Condition
                  </label>
                  <select
                    id={`select-condition-${idx}`}
                    className="form-control"
                    defaultValue={el.condition || promptKeys[0]}
                    onChange={e =>
                      changeUIElementCondition(e.target.value, idx)
                    }
                  >
                    {promptKeys.map(val => (
                      <option key={Math.random()} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
            <button
              className="btn btn-small align-self-stretch"
              onClick={() => removeUIElement(el.key)}
            >
              <Icon name="x-lg" className="pe-none" />
            </button>
          </div>
        </div>
      </div>
    ))}
  />
);

const SortableItem = SortableElement(({ value }) => <>{value}</>);
const SortableList = SortableContainer(({ items }) => (
  <ol>
    {items.map((value, index) => (
      <SortableItem key={`item-${Math.random()}`} index={index} value={value} />
    ))}
  </ol>
));

const Modal = observer(
  ({ showModal, setShowModal, action, negativeAction }) => (
    <BasicModal
      title="Do you want to save before continuing?"
      showModal={showModal}
      setShowModal={setShowModal}
      action={action}
      negativeAction={negativeAction}
      yesText="Save edits"
      yesPostive
      noText="Continue without"
      hasBackground
    />
  )
);

export default withRouter(Body);
