const dev = {
  baseURL: "http://localhost:3080/api/",
  landingPageUrl: "http://localhost:3080",
  domain: process.env.REACT_APP_DOMAIN,
  ws: process.env.REACT_APP_WS,
  backend: process.env.REACT_APP_BACKEND,
  stripe: {
    free: process.env.STRIPE_FREE_DEV,
    entry: process.env.STRIPE_ENTRY_DEV,
    pro: process.env.STRIPE_PRO_DEV,
  },
};

const prod = {
  baseURL: "/api/",
  landingPageUrl: process.env.LANDING_PAGE_URL,
  domain: process.env.REACT_APP_DOMAIN,
  ws: process.env.REACT_APP_WS,
  backend: process.env.REACT_APP_BACKEND,
  stripe: {
    free: process.env.STRIPE_PRODUCT_FREE,
    entry: process.env.STRIPE_PRODUCT_ENTRY,
    pro: process.env.STRIPE_PRODUCT_PRO,
  },
};
const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;
