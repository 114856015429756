import React from "react";

const SmallPopup = ({
  showModal,
  title,
  action,
  setShowModal,
  yesText,
  noText,
}) => (
  <>
    {showModal ? (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed right-0 left-0 bottom-2 z-50 outline-none focus:outline-none">
          <div className="relative w-auto mx-auto w-auto">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between rounded-lg bg-gray-700">
                <div className="flex items-start justify-between p-2 text-white">
                  <h3 className="text-sm font-semibold">{`${title}`}</h3>
                </div>
                <div>
                  <button
                    className="text-blue-300 text-sm px-1 py-1 mx-4"
                    type="button"
                    onClick={async () => {
                      action();
                      setShowModal(false);
                    }}
                  >
                    {yesText || "Yes"}
                  </button>
                  <button
                    className="text-blue-300 text-sm px-1 py-1 rounded mr-4"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    {noText || "Undo"}
                  </button>
                  <button
                    className="text-white font-bold uppercase text-sm px-1 py-1 mr-2"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : null}
  </>
);

export default SmallPopup;
