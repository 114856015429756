import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";

@observer
class LockedScreeen extends Component {
  render() {
    const { title, message, darkMode, url, urlText } = this.props;
    return (
      <main className={`${darkMode ? "text-bg-dark" : ""} `}>
        <div className="card m-auto" style={{ width: "30rem" }}>
          <div className="card-body">
            <h1>{title}</h1>
            <div className="mb-3">{message}</div>
            <div>
              {url && urlText && (
                <a href={url} rel="noreferrer" target="_blank">
                  {urlText}
                </a>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(LockedScreeen);
