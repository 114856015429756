import React from "react";

export function Body(props) {
  return <div style={{ overflow: "auto" }}>{props.children}</div>;
}

export function Grid(props) {
  return (
    <div className="grid grid-cols-6 xl:grid-cols-12 xl:gap-12">
      {props.children}
    </div>
  );
}

export function Col({ span, children, className }) {
  return (
    <div className={`col-span-${span || "6"} ${className}`}>{children}</div>
  );
}

export default Body;
