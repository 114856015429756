import React, { Component, createRef } from "react";
import Countdown from "react-countdown";
import { withRouter } from "react-router-dom";
import { observable, makeObservable, computed } from "mobx";
import { observer, inject } from "mobx-react";
import {
  PencilIcon,
  ClockIcon,
  InformationCircleIcon,
  DuplicateIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import {
  UserIcon,
  ViewListIcon,
  ClipboardListIcon,
  ChevronRightIcon,
  MenuAlt2Icon,
} from "@heroicons/react/solid";
import { Helmet } from "react-helmet-async";

import Header from "../Components/Header";
import Body, { Grid, Col } from "../Components/Body";
import Button from "../Components/Button";
import Output from "../Components/Output";
import EntryTabs from "../Components/EntryTabs";
import EntryPrompt from "../Components/EntryPrompt";
import EntryInput from "../Components/EntryInput";
import EntryN from "../Components/EntryN";
import Models from "../Models";
import {
  checkIfConditionalOutput,
  checkIfHeading,
  checkIfNumberValue,
  checkIfOutput,
  checkIfOutputAsValue,
  checkIfOutputNumberValue,
  checkIfOutputs,
  checkIfOutputSelect,
  checkIfOutputSubmit,
  checkIfOutputValue,
  checkIfSelect,
  checkIfSubmit,
  checkIfValue,
  isChatCheck,
  UITypes,
} from "../NewTool/helper";
import config from "../config";
import LockedScreen from "./LockedScreen";

const { Tool } = Models;
const { v4: uuidv4 } = require("uuid");

@inject("store")
@observer
class SharedTool extends Component {
  @observable tokenId = "";
  @observable tool = {};

  @observable.deep prompts = [];
  @observable currentPrompt = 0;
  @observable currentOption = "Start Using";

  @observable error = "";

  @observable output = {};
  @observable history = {};
  @observable outputs = {};
  @observable code = "";

  @observable loading = false;

  @observable date = Date.now() + 1000;
  countdown = [];
  clearExampleTimeout = [];

  shouldLock = false;
  @observable isLocked = false;
  @observable shouldShowLockScreen = false;

  toolSession = "";

  ws = createRef();

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount(_prevProps, _prevState, _snapshot) {
    const pathName = this.props.location.pathname;
    this.tokenId = pathName.substring(pathName.lastIndexOf("/") + 1);

    const tool = await this.props.store.api
      .get(`/public-tools/get-tool/${this.tokenId}`)
      .then(({ data }) => data);
    this.tool = Tool.fromJSON(tool);
    this.prompts = [...this.tool.prompts];

    if (this.tool.isLocked && this.tool.enableOneIpSession) {
      this.isLocked = true;
      this.shouldShowLockScreen = this.tool.customMessages?.lockedMessage;
      if (!this.shouldShowLockScreen)
        alert(
          tool.customMessages?.lockedMessage || "Chat session already used"
        );
    } else if (this.tool.enableOneSession) {
      if (this.props.store.isLocked(this.tool.id)) {
        this.isLocked = true;
        this.shouldShowLockScreen = this.tool.customMessages?.lockedMessage;
        if (!this.shouldShowLockScreen)
          alert(
            tool.customMessages?.lockedMessage || "Chat session already used"
          );
      } else {
        console.log("MAYBE");
        this.shouldLock = true;
      }
    }

    this.toolSession = uuidv4();
  }

  @computed get isGenerateButtonDisabled() {
    if (this.loading || this.isLocked) {
      return true;
    }
    return false;
  }

  @computed get disabled() {
    if (
      this.prompts[this.currentPrompt].prompts[0].value.length < 1 ||
      this.isLocked
    ) {
      return true;
    }
    return false;
  }

  @computed get language() {
    let language = "";
    this.prompts[this.currentPrompt].prompts.forEach(prompt => {
      if (prompt.attr === "language") {
        language = `${prompt.value}`;
      }
    });
    return language;
  }

  handleCurrentPrompt = val => {
    this.currentPrompt = val;
  };

  checkMinimumPrompts = () => {
    let shouldReturn = false;

    this.prompts[this.currentPrompt].prompts.forEach(prompt => {
      if (prompt.min) {
        if (
          checkIfNumberValue(prompt.type) ||
          checkIfOutputNumberValue(prompt.type)
        ) {
          if (!prompt.value) prompt.value = prompt.min;
        }
        if (prompt.value.length < prompt.min) {
          shouldReturn = true;
          prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
        }
      }
    });

    return shouldReturn;
  };

  onStartUsing = async () => {
    this.loading = false;
    this.error = "";
    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });
    this.currentOption = "Start Using";
  };

  onExample = async () => {
    this.loading = true;
    this.error = "";
    this.code = ``;

    this.currentOption = "Example";

    let totalLength = 0;

    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      this.prompts[this.currentPrompt].prompts[promptIndex].value = "";
    });

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      for (
        let timeoutIndex = 0;
        timeoutIndex < prompt.example.length;
        timeoutIndex++
      ) {
        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.prompts[this.currentPrompt].prompts[promptIndex].value +=
            prompt.example[timeoutIndex];
        }, 7 * totalLength);
      }
    });

    totalLength++;

    if (this.prompts[this.currentPrompt].example.output) {
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.output = this.prompts[this.currentPrompt].example.output;
        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.loading = false;
          this.currentOption = "Start Using";
          this.prompts[this.currentPrompt].prompts[0].value += " ";
        }, 7 * totalLength + this.prompts[this.currentPrompt].example.output.length * 7 + 500);
      }, 7 * totalLength + 500);
    }

    if (this.prompts[this.currentPrompt].example.code) {
      totalLength++;
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.code = `${this.prompts[this.currentPrompt].example.code}`;
        this.loading = false;
      }, 7 * totalLength + 500);
    }

    if (this.prompts[this.currentPrompt].example.outputs) {
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.outputs = this.prompts[this.currentPrompt].example.outputs;

        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.loading = false;
          this.currentOption = "Start Using";
        }, 7 * totalLength + 500);
      }, 7 * totalLength + 500);
    }
  };

  contentFilterFlagged = async response => {
    this.error = response.message;

    this.date = Date.now() + 5000;
    this.countdown.forEach(countdown => {
      if (countdown) {
        countdown.stop();
        countdown.start();
      }
    });
    this.loading = false;
  };

  checkOutput = output => {
    if (output) {
      output = output.replace(/^\s+|\s+$/g, "");
      // output = output.replace(/\s{2,}/g, ' ')
    }
    return output;
  };

  onGenerateClick = async (key, type) => {
    try {
      this.error = "";
      this.code = "";
      this.loading = true;

      const checkMinimumPrompts = this.checkMinimumPrompts();
      if (checkMinimumPrompts) {
        this.loading = false;
        return false;
      }

      const postObj = {};
      const prompts = Object.keys(this.tool.aiPrompts);
      postObj.tool = this.tool.id;
      postObj.visitorId = this.props.store.getVisitorId();
      postObj.toolSession = this.toolSession;
      postObj.shouldLock = this.shouldLock;

      if (checkIfSubmit(type)) {
        if (!this.history[key]) {
          this.history[key] = [];
        }
        this.prompts[this.currentPrompt].prompts
          .filter(
            el =>
              checkIfValue(el.type) ||
              checkIfSelect(el.type) ||
              checkIfNumberValue(el.type)
          )
          .forEach(prompt => {
            const attr = prompt.attr.replace(` [${key}]`, "");
            const value = checkIfSelect(prompt.type)
              ? Array.isArray(prompt.value)
                ? prompt.value.map(el => el.value).join(", ")
                : prompt.value.value
              : prompt.value;
            if (
              isChatCheck(
                this.tool.aiPrompts[
                  Object.keys(this.tool.aiPrompts)[this.currentPrompt]
                ].model
              )
            ) {
              if (this.history[key].length > 0) {
                postObj["--internal-history"] = [...this.history[key]];
              }

              this.history[key].push({ role: "user", content: value });
            }
            postObj[attr] = value;
          });
      } else {
        this.prompts[this.currentPrompt].prompts
          .filter(el => el.attr.includes(`[${key}]`))
          .filter(
            el =>
              checkIfOutputValue(el.type) ||
              checkIfOutputSelect(el.type) ||
              checkIfOutputNumberValue(el.type)
          )
          .forEach(prompt => {
            const attr = prompt.attr.replace(` [${key}]`, "");
            const value = checkIfOutputSelect(prompt.type)
              ? Array.isArray(prompt.value)
                ? prompt.value.map(el => el.value).join(", ")
                : prompt.value.value
              : prompt.value;
            postObj[attr] = value;
          });
        const outputVariable = this.prompts[this.currentPrompt].prompts.find(
          el => checkIfOutputAsValue(el.type) && el.attr.includes(`[${key}]`)
        );
        if (outputVariable) {
          if (outputVariable.attr) {
            const attr = outputVariable.attr.replace(` [${key}]`, "");
            postObj[attr] = this.output[outputVariable.condition || prompts[0]];
          }
        }

        const hasOutput = this.prompts[this.currentPrompt].prompts.find(
          el => checkIfConditionalOutput(el.type) && el.attr === key
        );
        if (!hasOutput) [key] = prompts;
      }

      postObj.currentPrompt = key;
      if (this.prompts[this.currentPrompt].n) {
        postObj.n = this.prompts[this.currentPrompt].n;
      }

      postObj.tokenId = this.tokenId;
      const sessionId = uuidv4();

      this.ws.current = new WebSocket(
        `${config.ws}/shared-tool/${sessionId}`,
        this.tokenId
      );

      this.ws.current.onopen = () => {
        this.output[key] = "";
        this.ws.current.send(
          JSON.stringify({
            session: sessionId,
            data: postObj,
          })
        );
      };
      this.ws.current.onmessage = ev => {
        const data = JSON.parse(ev.data);
        if (data.type === "done") {
          console.log("Credits: ", data.message);
          this.props.store.updateCredits(data.message);
          this.ws.current.close();
          return;
        }
        if (data.type === "error") {
          this.error = data.message;
          this.ws.current.close();
          return;
        }
        this.output[key] += data.message ? data.message : "";
      };

      this.ws.current.onclose = () => {
        this.date = Date.now() + 10000;
        this.countdown.forEach(countdown => {
          if (countdown) {
            countdown.stop();
            countdown.start();
          }
        });
        this.loading = false;
        if (this.shouldLock) {
          this.shouldLock = false;
          this.props.store.setLockedTools(this.tool.id);
        }
      };
      this.ws.current.onerror = error => {
        this.error = !error.target.protocol
          ? this.tool.customMessages?.failMessage ||
            `The user may not be authenticated or not have enough credits.`
          : this.tool.customMessages?.privateRelayMessage ||
            "Oops! Message processing failed. Please try again.";
      };
    } catch (error) {
      console.log(error);
      this.countdown.forEach(countdown => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      this.loading = false;
    }
    return true;
  };

  getIcon = () => {
    if (!this.tool) return null;
    if (this.tool.Icon === "UserIcon") return UserIcon;
    if (this.tool.Icon === "ViewListIcon") return ViewListIcon;
    if (this.tool.Icon === "ClipboardListIcon") return ClipboardListIcon;
    if (this.tool.Icon === "ChevronRightIcon") return ChevronRightIcon;
    if (this.tool.Icon === "EyeIcon") return EyeIcon;
    if (this.tool.Icon === "MenuAlt2Icon") return MenuAlt2Icon;
    return null;
  };

  getOutputIcon = () => {
    if (!this.tool) return null;
    if (this.tool.output.Icon === "UserIcon" || this.tool.Icon === "UserIcon") {
      return UserIcon;
    }
    if (
      this.tool.output.Icon === "ViewListIcon" ||
      this.tool.Icon === "ViewListIcon"
    ) {
      return ViewListIcon;
    }
    if (
      this.tool.output.Icon === "ClipboardListIcon" ||
      this.tool.Icon === "ClipboardListIcon"
    ) {
      return ClipboardListIcon;
    }
    if (
      this.tool.output.Icon === "ChevronRightIcon" ||
      this.tool.Icon === "ChevronRightIcon"
    ) {
      return ChevronRightIcon;
    }
    if (this.tool.output.Icon === "EyeIcon" || this.tool.Icon === "EyeIcon") {
      return EyeIcon;
    }
    if (
      this.tool.output.Icon === "MenuAlt2Icon" ||
      this.tool.Icon === "MenuAlt2Icon"
    ) {
      return MenuAlt2Icon;
    }
    return null;
  };

  render() {
    if (!this.prompts.length) return null;
    if (this.shouldShowLockScreen)
      return (
        <LockedScreen
          darkMode={false}
          title={this.tool.title}
          message={this.tool.customMessages?.lockedMessage}
          url={this.tool.options?.url}
          urlText={this.tool.options?.urlText}
        />
      );

    // required for mobx to pick up deeply nested value
    const currentValue = this.prompts[this.currentPrompt].prompts[0].value;

    const hasOutput =
      this.prompts[this.currentPrompt].prompts.length &&
      this.prompts[this.currentPrompt].prompts.find(el =>
        checkIfOutput(el.type)
      );
    const submit =
      this.prompts[this.currentPrompt].prompts.length &&
      this.prompts[this.currentPrompt].prompts.find(el =>
        checkIfSubmit(el.type)
      );

    const prompts = Object.keys(this.tool.aiPrompts);

    const improveSubmits =
      (this.prompts[this.currentPrompt].prompts.length &&
        this.prompts[this.currentPrompt].prompts.reduce((res, val) => {
          if (checkIfOutputSubmit(val.type)) {
            res.push(val);
          }
          return res;
        }, [])) ||
      [];

    const showOutputPart = key =>
      this.output[key] &&
      this.prompts[this.currentPrompt].prompts.filter(
        el =>
          checkIfOutputs(el.type) &&
          !checkIfOutputSubmit(el.type) &&
          (el.condition || prompts[0]) === key
      ).length > 0;

    const conditionalOutput = (key, condition) => {
      const res = this.prompts[this.currentPrompt].prompts.find(
        el =>
          checkIfConditionalOutput(el.type) &&
          el.attr === key &&
          el.condition === condition
      );
      return res;
    };

    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} Shared Tool - Vuo AI - ${this.tokenId}`}</title>
        </Helmet>
        <Header
          title={this.tool.title}
          toolId={this.tool._id}
          desc={this.tool.desc}
          Icon={this.getIcon()}
          fromColor={this.tool.fromColor}
          category={this.tool.category}
          options={[
            {
              title: "Start Using",
              Icon: PencilIcon,
              color: this.props.store.profile.credits ? "green" : "red",
              onClick: this.onStartUsing,
            },
            {
              title: "Example",
              color: "yellow",
              Icon: InformationCircleIcon,
              onClick: this.onExample,
            },
          ]}
          currentOption={this.currentOption}
        />
        <Body>
          <div className="container">
            <div className="row">
              <div className="col-4">
                <EntryTabs
                  prompts={this.prompts}
                  currentPrompt={this.currentPrompt}
                  onChange={this.handleCurrentPrompt}
                />
                {this.prompts.map((prompt, index) => (
                  <div key={index}>
                    <EntryPrompt
                      prompt={prompt}
                      key={index}
                      index={index}
                      disabled={this.disabled}
                      currentPrompt={this.currentPrompt}
                    >
                      {prompt.prompts
                        .filter(
                          el =>
                            checkIfHeading(el.type) ||
                            checkIfValue(el.type) ||
                            checkIfNumberValue(el.type) ||
                            checkIfSelect(el.type)
                        )
                        .map((promptInput, idx) => (
                          <EntryInput
                            prompt={promptInput}
                            key={idx}
                            language={this.language}
                            index={idx}
                            disabled={this.disabled}
                          />
                        ))}

                      <div className="mt-4">
                        {this.error && (
                          <label
                            className={`${
                              this.error ? "text-red-400" : "text-gray-400"
                            } font-medium transition-all`}
                          >
                            {this.error}
                          </label>
                        )}
                      </div>

                      {submit ? (
                        <div className="md:flex">
                          <Countdown
                            ref={countdown => (this.countdown[0] = countdown)}
                            date={this.date}
                            renderer={props => (
                              <Button
                                title={
                                  props.total
                                    ? `Timeout ${props.total / 1000} secs`
                                    : submit.title || "Perform Request"
                                }
                                disabled={
                                  props.total || this.isGenerateButtonDisabled
                                }
                                Icon={
                                  props.total
                                    ? ClockIcon
                                    : currentValue
                                    ? DuplicateIcon
                                    : PencilIcon
                                }
                                onClick={() =>
                                  this.onGenerateClick(
                                    submit.attr,
                                    UITypes.SubmitBtn.key
                                  )
                                }
                              />
                            )}
                          />
                          <EntryN
                            prompts={this.prompts}
                            currentPrompt={this.currentPrompt}
                          />
                        </div>
                      ) : null}
                    </EntryPrompt>

                    {hasOutput && this.currentPrompt === index ? (
                      <Col span="6">
                        <Output
                          title={this.tool.output.title}
                          desc={this.tool.output.desc}
                          Icon={this.getOutputIcon()}
                          fromColor={this.tool.fromColor}
                          toColor={this.tool.toColor}
                          loading={this.loading}
                          output={this.output[submit.attr]}
                          outputs={this.outputs[submit.attr]}
                          code={this.code}
                          language={this.language}
                          outputsColor={this.tool.output.color}
                          OutputsIcon={this.getOutputIcon()}
                        />
                      </Col>
                    ) : null}
                    {improveSubmits
                      ?.filter(el => showOutputPart(el.condition))
                      .map(key => (
                        <div key={Math.random()}>
                          <EntryPrompt
                            prompt={prompt}
                            currentPrompt={index}
                            index={index}
                          >
                            {prompt.prompts
                              .filter(
                                el =>
                                  checkIfOutputs(el.type) &&
                                  (el.condition || prompts[0]) ===
                                    key.condition &&
                                  el.attr.includes(`[${key.attr}]`) &&
                                  !checkIfOutputAsValue(el.type) &&
                                  !checkIfOutputSubmit(el.type)
                              )
                              .map((promptInput, idx) => (
                                <EntryInput
                                  prompt={promptInput}
                                  key={idx}
                                  language=""
                                  index={idx}
                                  updateValue={this.updateUIPromptValue}
                                />
                              ))}
                            {key ? (
                              <div className="flex">
                                <Countdown
                                  ref={countdown =>
                                    (this.countdown[1] = countdown)
                                  }
                                  date={this.date}
                                  renderer={props => (
                                    <Button
                                      title={
                                        props.total
                                          ? `Timeout ${props.total / 1000} secs`
                                          : key.title || "Perform Request"
                                      }
                                      disabled={
                                        props.total ||
                                        this.isGenerateButtonDisabled
                                      }
                                      Icon={
                                        props.total
                                          ? ClockIcon
                                          : currentValue
                                          ? DuplicateIcon
                                          : PencilIcon
                                      }
                                      onClick={() =>
                                        this.onGenerateClick(
                                          key.attr,
                                          UITypes.OutputSubmitBtn.key
                                        )
                                      }
                                    />
                                  )}
                                />
                              </div>
                            ) : null}

                            {this.error && (
                              <div className="mt-4">
                                <label className="text-danger">
                                  {this.error}
                                </label>
                              </div>
                            )}
                          </EntryPrompt>
                          {conditionalOutput(key.attr, key.condition) ? (
                            <Output
                              title={this.tool.output.title}
                              desc={this.tool.output.desc}
                              Icon={this.getIcon()}
                              fromColor={this.tool.fromColor}
                              toColor={this.tool.toColor}
                              loading={this.loading}
                              output={this.output[key.attr]}
                              outputs={this.outputs[key.attr]}
                              code={this.code}
                              language={this.language}
                            />
                          ) : null}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Body>
      </>
    );
  }
}

export default withRouter(SharedTool);
