
import React, { Component } from 'react';

import { withRouter, Redirect } from "react-router-dom";
import Loading from '../Components/Loader'
import { observable, makeObservable, } from 'mobx'
import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class Login extends Component {


	@observable loading = true

	constructor(props) {
		super(props)
		makeObservable(this)

	}
	componentDidMount() {
		this.refresh = setInterval(() => {
			this.props.store.refreshTokenAndProfile();
			if (this.props.store.profile.status) {
				this.loading = false
			}
		}, 1000);
	}
	componentWillUnmount() {
		clearInterval(this.refresh)
	}

	render() {
		return (
			<>
				{this.loading ? null : <Redirect to="/" />}
				<div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">

					<div className="text-center mb-6">
						<Logo />
						<div>
							<div className="text-4xl font-bold text-gray-700">sub<span className="text-green-600 font-medium">editor</span><span className="text-gray-500 font-normal ">.ai</span></div>
						</div>
					</div>
					<div className={`min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all  transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2`}>

						<div className="px-4 py-8 text-center">
							<Loading className="w-24 h-24" active />
						</div>
						<div className="text-center ">
							<div className="text-3xl font-medium text-gray-900">
								Generating Your Profile
							</div>
							<p className="text-lg text-gray-500">
								1. Preparing your credits
							</p>

							<div className="flex flex-col p-4">
								<div className="font-medium rounded-lg text-lg px-4 py-4 bg-gray-300 text-white mt-4 border border-gray-300 inline-block" >
									Nearly Done...
								</div>
							</div>
						</div>
					</div>
				</div>

			</>)
	}
}



const Logo = () => (
	<svg width="400" height="50" viewBox="0 0 812 109" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.25013 14.8778C16.7067 43.3814 40.3763 67.051 68.8799 83.5076C97.3835 99.9641 129.717 108.628 162.63 108.628C195.543 108.628 227.876 99.9641 256.38 83.5075C284.883 67.051 308.553 43.3814 325.01 14.8778L300.693 0.838781C286.701 25.074 266.576 45.1991 242.341 59.1913C218.106 73.1835 190.614 80.5498 162.63 80.5498C134.645 80.5498 107.154 73.1835 82.9189 59.1913C58.6837 45.1991 38.5586 25.074 24.5664 0.838826L0.25013 14.8778Z" fill="#00054A" />
		<path d="M341.63 46.1278C341.63 62.7038 348.215 78.6009 359.936 90.322C371.657 102.043 387.554 108.628 404.13 108.628C420.706 108.628 436.603 102.043 448.324 90.322C460.045 78.601 466.63 62.7038 466.63 46.1278L441.63 46.1278C441.63 56.0734 437.679 65.6117 430.646 72.6443C423.614 79.6769 414.075 83.6278 404.13 83.6278C394.184 83.6278 384.646 79.6769 377.613 72.6443C370.581 65.6117 366.63 56.0734 366.63 46.1278L341.63 46.1278Z" fill="#00054A" />
		<path fillRule="evenodd" clipRule="evenodd" d="M791.797 54.2589C791.797 54.2589 791.794 54.2796 791.781 54.3205C791.789 54.2792 791.797 54.2589 791.797 54.2589ZM786.583 59.9091C790.172 57.1247 790.172 52.3419 786.583 49.5575C780.776 45.0524 771.123 40.1279 757.407 35.6272C730.191 26.697 691.331 20.8387 647.5 20.8387C603.669 20.8387 564.809 26.697 537.593 35.6272C523.877 40.1279 514.224 45.0524 508.417 49.5575C504.827 52.3419 504.827 57.1247 508.417 59.9091C514.224 64.4142 523.877 69.3387 537.593 73.8393C564.809 82.7695 603.669 88.6278 647.5 88.6278C691.331 88.6278 730.191 82.7695 757.407 73.8393C771.123 69.3387 780.776 64.4142 786.583 59.9091ZM503.203 54.2589C503.203 54.2589 503.211 54.2792 503.218 54.3205C503.205 54.2796 503.203 54.2589 503.203 54.2589ZM503.203 55.2077C503.203 55.2077 503.205 55.1869 503.218 55.146C503.211 55.1873 503.203 55.2077 503.203 55.2077ZM791.781 55.1461C791.794 55.1869 791.797 55.2077 791.797 55.2077C791.797 55.2077 791.789 55.1874 791.781 55.1461ZM647.5 108.628C738.213 108.628 811.75 84.4984 811.75 54.7333C811.75 24.9681 738.213 0.838745 647.5 0.838745C556.787 0.838745 483.25 24.9681 483.25 54.7333C483.25 84.4984 556.787 108.628 647.5 108.628Z" fill="#00054A" />
	</svg>
);



export default withRouter(Login)