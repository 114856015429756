/* eslint-disable global-require */
import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { observable, makeObservable } from "mobx";
import { NavLink, withRouter } from "react-router-dom";

@inject("store")
@observer
class SidebarCompontent extends Component {
  @observable isChatPath = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    if (this.props.location.pathname === "/signup") {
      this.props.history.push("/");
    }
    if (this.props.location.pathname === "/login") {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.shortcutHandler);
  }
  componentDidUpdate() {
    const aiPaths = this.props.location.pathname.split("/");
    this.isChatPath = aiPaths[1] === "tools-chat";
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.shortcutHandler);
  }

  onKeyUp = e => {
    if (this.props.location.pathname !== "/search") {
      this.props.history.push("/search");
    }
    if (e.keyCode === 8) {
      if (this.props.store.toolsKeyword === "") {
        this.props.history.push("/");
      }
    }
  };

  render() {
    return (
      <nav className="nav nav-main nav-pills p-3">
        {/* What & why is this textarea? */}
        <Textarea
          readOnly
          name="copy-textarea"
          id="copy-textarea"
          value={this.props.store.copyToClipboardText}
        />
        <NavLink to="/" className="nav-logo">
          <img src="/logo.svg" alt="Vuo AI logo" width="100" />
        </NavLink>
        <div className="me-2"></div>
        {this.props.store.isCreator && (
          <NavLink
            to="/new-tool"
            exact
            onClick={() => (this.props.store.toolsKeyword = "")}
            className="nav-link"
            activeClassName="active"
          >
            New tool
          </NavLink>
        )}
        {this.props.store.isCreator && (
          <NavLink
            to="/my-tools"
            exact
            onClick={() => (this.props.store.toolsKeyword = "")}
            className="nav-link"
            activeClassName="active"
          >
            My tools
          </NavLink>
        )}
        <NavLink
          to="/"
          exact
          onClick={() => (this.props.store.toolsKeyword = "")}
          className="nav-link"
          activeClassName="active"
        >
          All tools
        </NavLink>
        <div className="flex-grow" />

        {this.props.store.isCreator && (
          <NavLink
            to="/context"
            exact
            onClick={() => (this.props.store.toolsKeyword = "")}
            className="nav-link"
            activeClassName="active"
          >
            Context
          </NavLink>
        )}
        <div className="me-3" />
        <div className="nav-profile">
          <small>
            {this.props.store.profile.email}
            <br />
            {this.props.store.profile.credits}&nbsp; credits
          </small>
        </div>
        <div className="me-3" />
        <button
          type="button"
          className="nav-link"
          onClick={this.props.store.handleLogout}
        >
          Sign out
        </button>
        <div className="me-1" />
        {/*
          TODO: Move search to tools page. Should not be in the main nav.
          <form className="form-floating" role="search">
            <label
              className="form-label text-nowrap"
              htmlFor="search"
            >
              Search (Ctrl + K)
            </label>
            <Input
              type="text"
              id="search"
              className="form-control form-control-sm"
              name="q"
              autoComplete="off"
              value={this.props.store.toolsKeyword}
              onChange={this.props.store.onChangeToolsKeyword}
              onKeyUp={this.onKeyUp}
            />
          </form>
        */}
        {this.props.children}
      </nav>
    );
  }
}

const Input = styled.input``;

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

export default withRouter(SidebarCompontent);
